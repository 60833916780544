import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// import '../../../src/App.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import { imageName } from '../../assets/enums';
import Login from './Login'
import Signup from './Signup'
import Dashboard from './Dashboard';
import '../../App.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { LoginModal, SignupModal, ToastContain } from '../shared';
import { getUserNotif } from '../RestAPI/Rest';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Header(props) {
    const navigate = useNavigate();
    const [visibleLoginModal, setVisibleLoginModal] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [isLogin, setIsLogin] = useState(false);
    const [visibleCreateAccountModal, setVisibleCreateAccountModal] = useState(false);
    const [userInfo, setuserInfo] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        getUserData();
    }, []);

    // for getting user data from local storage
    const getUserData = () => {
        if (JSON.parse(localStorage.getItem("userInfo"))) {
            setIsLogin(true);
            setuserInfo(JSON.parse(localStorage.getItem("userInfo")).data.user);
            getNotif();
        } else {
            setIsLogin(false);
        }
    }

    // get notification count
    const getNotif = () => {
        var data = { 'pageNo': 1, 'limit': 10 };
        getUserNotif(data).then(result => {
            var notiCount = 0;
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (result.success) {
                for (let i = 0; i < result.data.data.length; i++) {
                    if (result.data.data[i].isSeen == false) {
                        notiCount++;
                    }
                }
            }
            setNotificationCount(notiCount);
        })
    }

    // for valuation dash
    const routeValuation = () => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            toast.warning("Please LOGIN to go to Valuation Dashboard.");
            let path = `/`;
            navigate(path);
        } else {
            let path = `/valuation-dashboard`;
            navigate(path);
        }
    }

    // for Appraisal dash
    const routeAppraisalDash = () => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            toast.warning("Please LOGIN to go to Appraisal Dashboard.");
            let path = `/`;
            navigate(path);
        } else {
            let path = `/appraisal-dashboard`;
            navigate(path);
        }
    }

    // for rot in the home
    const routeHome = () => {
        let path = `/`;
        navigate(path);
    }

    // for rot in the help
    const routeHelp = () => {
        let path = `/help`;
        navigate(path);
    }

    // for rot in the notification list
    const goNotif = () => {
        let path = `/notifications`;
        navigate(path);
    }

    // for view the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // for close the menu
    const handleclose = () => {
        setAnchorEl(null);
    };

    // for logout function
    const logoutFun = () => {
        handleclose();
        setIsLogin(false);
        setuserInfo({});
        toast.success("Successfully Logged Out");
        localStorage.clear();
        routeHome();
    }

    // for set the login data
    const onSetLoginData = (value) => {
        setuserInfo(value);
        setIsLogin(true);
    }



    // for menu section
    const menu = () => {

        // redirect to account
        const goAccSett = () => {
            let path = `/account-setting`;
            navigate(path);
        }

        // redirect to builling
        const goBilling = () => {
            let path = `/billing`;
            navigate(path);
        }


        return (
            
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleclose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem><b>{userInfo.firstName + " " + userInfo.lastName}</b></MenuItem>
                <div className='line'></div>
                <MenuItem onClick={() => goAccSett()}><img src={imageName.edit} style={{ cursor: 'pointer' }} width='20'></img>&nbsp;&nbsp;Edit profile</MenuItem>
                <MenuItem onClick={() => goBilling()}><img src={imageName.card} style={{ cursor: 'pointer' }} width='20'></img>&nbsp;&nbsp;Billing</MenuItem>
                <MenuItem onClick={() => logoutFun()}><img src={imageName.logout} style={{ cursor: 'pointer' }} width='25'></img>&nbsp;&nbsp;Logout</MenuItem>
            </Menu>
        )
    }

    return (
        <div className="col-md-12 menu-card">
            <div className='col-md-12'>
                <div className="row">
                    <div className="col-md-4">
                        <h2 className='logotxt'>
                            <span onClick={() => routeHome()} style={{ cursor: 'pointer' }}>
                                <img className='logo' src={imageName.logo} />
                            </span>
                        </h2>
                    </div>
                    <div className="col-md-4">
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-4' onClick={() => routeValuation()}>
                                        <img className='dollar' style={{ cursor: 'pointer' }} src={imageName.valuation} />
                                    </div>
                                    <div className='col-md-8' onClick={() => routeValuation()}>
                                        <h5 className='valdash'>Valuation<br></br>Dashboard</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-4' onClick={() => routeAppraisalDash()}>
                                        <img className='coins' style={{ cursor: 'pointer' }} src={imageName.appraisal}></img>
                                    </div>
                                    <div className='col-md-8' onClick={() => routeAppraisalDash()}>
                                        <h5 className='appdash'>Appraisal <br></br>Dashboard</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='row mt-2'>
                            <div className='col-md-4 just-end'>
                                <img className='qsmark' style={{ cursor: 'pointer' }} src={imageName.help} onClick={routeHelp}></img>
                            </div>
                            <div className='col-md-8'>
                                <div className='row'>
                                    {isLogin ?
                                        <div className='col-md-8 bell just-start'>
                                            <a href="" className="text-dark" onClick={() => goNotif()}>
                                                <i className="fas fa-bell fa-2x"></i>
                                                {notificationCount > 0 ?
                                                    <span className="badge rounded-pill badge-notification bg-danger">{notificationCount}</span> :
                                                    null
                                                }
                                            </a>
                                        </div>
                                        :
                                        <div className='col-md-8 px-0'>
                                            <h5 className='login' onClick={() => setVisibleLoginModal(true)} style={{ cursor: 'pointer' }}><b>LOGIN</b></h5>
                                            <h5 className='createAccTxt' style={{ cursor: 'pointer' }} onClick={() => setVisibleCreateAccountModal(true)}>Create an Account</h5>
                                        </div>
                                    }
                                    <div className='col-md-4 d-flex justify-content-center'>
                                        {Object.keys(userInfo).length > 0 ?
                                            userInfo.profileImage !== undefined && userInfo.profileImage !== null ?
                                                <img className='power' src={userInfo.profileImage}
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                />
                                                :
                                                <div className='profile'>
                                                    <h3 style={{ paddingBottom: '2px' }} aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}>{userInfo.firstName.charAt(0)}</h3>
                                                </div>
                                            :
                                            <img className='power' src={imageName.login} style={{ cursor: 'pointer' }} onClick={() => setVisibleLoginModal(true)} />
                                        }
                                        {menu()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for view the signup moadal */}
            <SignupModal
                isVisible={visibleCreateAccountModal}
                onRequestVisible={(value) => setVisibleCreateAccountModal(value)}
            // onLoggedIn={(value) => onSetLoginData(value)}
            />
            {/* for view the login moadal */}
            <LoginModal
                isVisible={visibleLoginModal}
                onRequestVisible={(value) => setVisibleLoginModal(value)}
                onLoggedIn={(value) => onSetLoginData(value)}
                onCreateNewAccount={(value) => setVisibleCreateAccountModal(true)}
            />
            {/* for toast container */}
            <ToastContain />
        </div>
    );


}

export default Header;