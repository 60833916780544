import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import Form from 'react-bootstrap/Form';
import { setItemAddResponse } from '../common/Common';
import { getCatOpt, uploadFile, getReason, addExamination } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const plus = require('../../assets/image/additem.png')

function Item() {
    const { pathname, hash, key } = useLocation();
    const { id } = useParams()
    const eid = id
    const MIN_COUNT = 3;
    const navigate = useNavigate();
    const [editFlag, setEditFlag] = useState(false);
    const [addFlag, setAddFlag] = useState(false)

    const [banner, setBanner] = useState('')
    const [banner1, setBanner1] = useState('')
    const [banfile, setbanfile] = useState('')
    const [reqprice, setReqprice] = useState('')

    var uploadedFiles = []
    var imgSrc = []

    const [value, setValue] = useState(false);
    const [imgSrcUrl, setimgSrcUrl] = useState([])
    const [catData, setCatData] = useState([]);
    const [reasonData, setreasonData] = useState([]);
    const [pricingData, setpricingData] = useState([]);

    const [valSelect, setvalSelect] = useState('');
    const [valAppraisal, setvalAppraisal] = useState(false);
    const [requestTypeId, setrequestTypeId] = useState(0);
    const [requestType, setrequestType] = useState(9002);
    const [fname, setfname] = useState('');
    const [address, setaddress] = useState('');
    const [reason, setReason] = useState(0);

    var [itemName, setitemName] = useState('');
    var [category, setcategory] = useState('');
    const [dynamicArr, setDynamicArr] = useState([])

    var dynamicForm = []
    var items = []
    const [item, setItem] = useState([]);

    var itemData = []

    var minImageSelect = 3
    var maxImageSelect = 5

    // Error Notify :
    const [imgCount, setimgCount] = useState('');
    const [categorynotify, setCategorynotify] = useState('');
    const [itemnotify, setItemnotify] = useState('');
    const [reqTypenotify, setreqTypenotify] = useState('');
    const [reasonNotify, setreasonNotify] = useState('');
    const [fnamenotify, setfnamenotify] = useState('');
    const [addressnotify, setaddressnotify] = useState('');
    const [examData, setExamData] = useState({});
    const [editItemData, setEditItemData] = useState({})
    const [errorindex, setErrorIndex] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            let path = `/`
            navigate(path)

        }
        if (pathname.includes("edit")) {
            // console.log(pathname)
            setEditFlag(true);
            if (localStorage.getItem("valueprosData")) {
                let examdata = JSON.parse(localStorage.getItem("valueprosData"));
                setExamData(examdata);
                setrequestTypeId(examdata.certificateType == 7002 ? 9001 : 9002);
            }

            if (localStorage.getItem("EditItemData")) {
                let editItemData = JSON.parse(localStorage.getItem("EditItemData"));
                setitemName(editItemData.itemName);
                setcategory(editItemData.category);
                setDynamicArr(editItemData.dynamicForm)
                setimgSrcUrl(editItemData.images);
                setReqprice(editItemData.price)
                setValue(true);
                setReason(editItemData.reasonId);
            }
        } else if (pathname.includes("add")) {
            setAddFlag(true);
            if (localStorage.getItem("valueprosData")) {
                let examdata = JSON.parse(localStorage.getItem("valueprosData"));
                setExamData(examdata);
                setrequestTypeId(examdata.certificateType == 7002 ? 9001 : 9002);
                setReason(examdata.reasonId);

                localStorage.removeItem("valueprosData")
            }
        } else {
            setEditFlag(false)
        }

        getCatOpt().then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            // console.log("cat option>>>>", result.data.option)
            setCatData(result.data.options)
            console.log(catData)
        })

        getReason().then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setreasonData(result.data)
            console.log(reasonData)
        })

    }, [])

    function setDynamicForm(e) {
        setDynamicArr([]);
        setErrorIndex("");
        setErrorMessage("");
        // console.log("Category>>>>>>", e.target.value)
        setcategory(e.target.value);
        catData.map((dy) => {
            if (dy.value === e.target.value) {
                // console.log("dynamic form>>>>", dy);
                let arr = dy.forms;
                arr.map((fm) => {
                    fm.selected = null;
                    fm.value = "";
                })
                setDynamicArr(arr);

            }
            // console.log(dynamicArr)
        })

    }

    const goAppraisal = () => {
        setItemnotify('')
        setCategorynotify('')
        setimgCount('')
        if (itemName == '') {
            setItemnotify('Please enter item')
            return false
        } else {
            setItemnotify('')
        }
        if (category == '') {
            setCategorynotify('Please select category')
            return false
        } else {
            setCategorynotify('')
        }
        if (validateDynamicForm(dynamicArr)) {
            setErrorMessage("");
            setErrorIndex('');
        } else {
            return false
        }
        if (imgSrcUrl.length < minImageSelect || imgSrcUrl.length > maxImageSelect) {
            setimgCount('Please add atleast 3 images and maximum of 5 images')
            return false
        } else {
            setimgCount('')
        }
        if (addFlag) {

            let arr = examData.items;
            let reqprice = 0;
            var certificateTypeId = 0;

            if (requestTypeId == 9001) {
                certificateTypeId = 7002
                reqprice = 75;
            } else if (requestTypeId == 9002) {
                certificateTypeId = 7001
                reqprice = 125;
            }

            var price = reqprice;

            var mainData = {
                "examinationNo": "",
                "reasonId": reason,
                "images": imgSrcUrl,
                "dynamicForm": dynamicArr,
                "itemDescription": "desc",
                "category": category,
                "itemName": itemName,
                "price": price,
                "quantity": 0,
                "discount": "",
                "pos": ""
            }
            arr.push(mainData);

            setItem(arr);


            var itemCount = arr.length
            var total = itemCount * price;

            let totalCount = 0;
            for (let i = 0; i < arr.length; i++) {
                totalCount = totalCount + arr[i].price;
            }


            var data1 = {
                "propertyId": 226,
                "examinationNo": "",
                // "reasonId": parseInt(reason),
                "reasonId": reason,
                "requestTypeId": parseInt(requestType),
                "certificateType": parseInt(certificateTypeId),
                "requestTimezone": "Asia/Kolkata",
                "items": arr,
                "price": parseInt(totalCount)
            }
            localStorage.setItem("valueprosData", JSON.stringify(data1))
            navigate("/item-list");
        } else {
            setvalAppraisal(true);
        }

    }

    const handleFormChange = (i, event) => {
        let data = [...dynamicArr];
        data[i].selected = event.target.value;
        data[i].value = event.target.value;
        // console.log("setDynamicarr val>>>>>>",data)
        setDynamicArr(data)
    }


    const validateDynamicForm = (dynamicFormData) => {
        let resCheck = true;
        for (let i = 0; i < dynamicFormData.length; i++) {
            if (dynamicFormData[i].required) {
                if (dynamicFormData[i].value.length == 0 || dynamicFormData[i].value == "Select") {
                    let message = ("Please " + (dynamicFormData[i].type == "select" ? "Select " : "Enter ") + dynamicFormData[i].title);
                    setErrorMessage(message);
                    setErrorIndex(i);
                    resCheck = false;
                    break;
                }
            }
        }
        return resCheck;
    }



    const moreData = () => {

        if (valAppraisal == false) {
            setItemnotify('')
            setCategorynotify('')
            setimgCount('')
            if (itemName == '') {
                setItemnotify('Please enter item')
                return false
            } else {
                setItemnotify('')
            }
            if (category == '') {
                setCategorynotify('Please select category')
                return false
            } else {
                setCategorynotify('')
            }
            if (validateDynamicForm(dynamicArr)) {
                setErrorMessage("");
                setErrorIndex('');
            } else {
                return false
            }
            if (imgSrcUrl.length < minImageSelect || imgSrcUrl.length > maxImageSelect) {
                setimgCount('Please add atleast 3 images and maximum of 5 images.')
                return false
            } else {
                setimgCount('')
            }
        }
        if (valAppraisal == true) {
            // setreqTypenotify('')
            // setreasonNotify('')
            if (requestTypeId == '') {
                setreqTypenotify('Please select request')
                return false
            }
            else {
                setreqTypenotify('')
            }
            if (reason == '') {
                setreasonNotify('Please select reason')
                return false
            }
            else {
                setreasonNotify('')
            }
        }

        let reqprice = 0;
        var certificateTypeId = 0;

        if (requestTypeId == 9001) {
            certificateTypeId = 7002
            reqprice = 75
        } else if (requestTypeId == 9002) {
            certificateTypeId = 7001
            reqprice = 125
        }

        if (addFlag) {
            let arr = examData.items;


            var mainData = {
                "examinationNo": "",
                "reasonId": reason,
                "images": imgSrcUrl,
                "dynamicForm": dynamicArr,
                "itemDescription": "desc",
                "category": category,
                "itemName": itemName,
                "price": reqprice,
                "quantity": 0,
                "discount": "",
                "pos": ""
            }
            arr.push(mainData);

            setItem(arr);

            var itemCount = arr.length
            var price = reqprice;
            var total = itemCount * price;

            let totalCount = 0;
            for (let i = 0; i < arr.length; i++) {
                totalCount = totalCount + arr[i].price;
            }

            var data1 = {
                "propertyId": 226,
                "examinationNo": "",
                // "reasonId": parseInt(reason),
                "reasonId": reason,
                "requestTypeId": parseInt(requestType),
                "certificateType": parseInt(certificateTypeId),
                "requestTimezone": "Asia/Kolkata",
                "items": arr,
                "price": parseInt(totalCount)
            }
            localStorage.setItem("valueprosData", JSON.stringify(data1))
            navigate("/item-list");
        } else if (Object.keys(examData).length > 0) {
            let arr = examData.items;
            var mainData = {
                "examinationNo": "",
                "reasonId": reason,
                "images": imgSrcUrl,
                "dynamicForm": dynamicArr,
                "itemDescription": "desc",
                "category": category,
                "itemName": itemName,
                "price": reqprice,
                "quantity": 0,
                "discount": "",
                "pos": ""
            }
            arr.push(mainData);
            setItem(arr);
            setTimeout(() => {
                console.log(">>>>>>>>>>>>>>>>>>>>>", item)
                toast.success("Item added successfully.");
            }, 1000);
            setDynamicArr([])
            setimgSrcUrl([])
            setitemName('');
            setcategory('');

        } else {
            var mainData = {
                "examinationNo": "",
                "reasonId": reason,
                "images": imgSrcUrl,
                "dynamicForm": dynamicArr,
                "itemDescription": "desc",
                "category": category,
                "itemName": itemName,
                "price": 0,
                "quantity": 0,
                "discount": "",
                "pos": ""
            }
            setTimeout(() => {
                console.log(">>>>>>>>>>>>>>HHHHH>>>>>>", item)
                toast.success("Item added successfully.");
            }, 1000);
            setDynamicArr([])
            setimgSrcUrl([])
            setitemName('');
            setcategory('');


            // let itm = [...item];
            // itm.push(mainData)
            // setItem(itm)

            // setItem(item => [...item, mainData])

            item.push(mainData)

            // setCatData([])
            // setvalSelect('')
            // getCatOpt().then(result => {
            //     setCatData(result.data.options)
            // })
        }
    }

    const clickImage = () =>{
        var uploadImg = document.getElementById('uploadImg');
        var inputFile = document.getElementById("inputFile");
        uploadImg.onclick = function() {
            inputFile.click(); // this will trigger the click event
        };
    }

    function nextEditTable() {
        console.log("valAppraisal>>>>>>>", valAppraisal)
        if (valAppraisal == false) {
            setItemnotify('')
            setCategorynotify('')
            setimgCount('')
            if (itemName == '') {
                setItemnotify('Please enter item')
                return false
            } else {
                setItemnotify('')
            }
            if (category == '') {
                setCategorynotify('Please select category')
                return false
            } else {
                setCategorynotify('')
            }
            if (validateDynamicForm(dynamicArr)) {
                setErrorMessage("");
                setErrorIndex('');
            } else {
                return false
            }
            if (imgSrcUrl.length < minImageSelect || imgSrcUrl.length > maxImageSelect) {
                setimgCount('Please add atleast 3 images and maximum of 5 images.')
                return false
            } else {
                setimgCount('')
            }
        }

        var newItems = JSON.parse(localStorage.getItem("valueprosData")).items;
        console.log(">>>>>>>>>>>>>>>>>>>>>>", newItems[eid].price)
        var mainData = {
            "examinationNo": "",
            "reasonId": reason,
            "images": imgSrcUrl,
            "dynamicForm": dynamicArr,
            "itemDescription": "desc",
            "category": category,
            "itemName": itemName,
            "price": newItems[eid].price,
            "quantity": 0,
            "discount": "",
            "pos": ""
        }
        setItem(item => [...item, mainData])

        item.push(mainData)

        setDynamicArr([])
        setimgSrcUrl([])
        setitemName('');
        setcategory('')

        if (requestTypeId == 9002) {
            var certificateTypeId = 7001
        } else {
            var certificateTypeId = 7002
        }
        var total = JSON.parse(localStorage.getItem("valueprosData")).price

        newItems[eid] = mainData;
        var data1 = {
            "propertyId": 226,
            "examinationNo": "",
            // "reasonId": parseInt(reason),
            "reasonId": reason,
            "requestTypeId": parseInt(requestType),
            "certificateType": parseInt(certificateTypeId),
            "requestTimezone": "Asia/Kolkata",
            "items": newItems,
            "price": parseInt(total)
        }

        localStorage.removeItem("EditItemData");

        localStorage.setItem("valueprosData", JSON.stringify(data1))

        navigate("/item-list");
    }

    const checkOut = () => {
        var reqprice = '';
        if (valAppraisal == false) {
            setItemnotify('')
            setCategorynotify('')
            setimgCount('')
            if (itemName == '') {
                setItemnotify('Please enter item')
                return false
            } else {
                setItemnotify('')
            }
            if (category == '') {
                setCategorynotify('Please select category')
                return false
            } else {
                setCategorynotify('')
            }
            if (validateDynamicForm(dynamicArr)) {
                setErrorMessage("");
                setErrorIndex('');
            } else {
                return false
            }
            if (imgSrcUrl.length < minImageSelect || imgSrcUrl.length > maxImageSelect) {
                setimgCount('Please add atleast 3 images and maximum of 5 images.')
                return false
            } else {
                setimgCount('')
            }
        }

        if (valAppraisal == true) {
            if (requestTypeId == '') {
                setreqTypenotify('Please select request')
                return false
            }
            else {
                setreqTypenotify('')
                if (requestTypeId == 9001) {
                    reqprice = 75
                }
                if (requestTypeId == 9002) {
                    reqprice = 125
                    if (reason == '') {
                        setreasonNotify('Please select reason')
                        return false
                    }
                    else {
                        setreasonNotify('')
                    }
                    if (fname == '') {
                        setfnamenotify('Please enter full name')
                        return false
                    }
                    else {
                        setfnamenotify('')
                    }
                    if (address == '') {
                        setaddressnotify('Please enter address')
                        return false
                    }
                    else {
                        setaddressnotify('')
                    }
                }
            }
        }

        var mainData = {
            "examinationNo": "",
            "reasonId": reason,
            "images": imgSrcUrl,
            "dynamicForm": dynamicArr,
            "itemDescription": "desc",
            "category": category,
            "itemName": itemName,
            "price": 0,
            "quantity": 0,
            "discount": "",
            "pos": ""
        }

        setItem(item => [...item, mainData]);

        item.push(mainData)

        setitemName('')
        setimgSrcUrl([])
        setCatData([])
        setvalSelect('')
        getCatOpt().then(result => {
            setCatData(result.data.options)
        })

        if (requestTypeId == 9002) {
            var certificateTypeId = 7001
        } else {
            var certificateTypeId = 7002
        }
        var itemCount = item.length
        var price = reqprice
        var total = itemCount * price

        let itemPrice = 0;
        if (requestTypeId == 9001) {
            itemPrice = 75;
        } else if (requestTypeId == 9002) {
            itemPrice = 125;
        }

        let totalCount = 0;
        for (let i = 0; i < item.length; i++) {
            item[i].price = itemPrice;
            totalCount = totalCount + item[i].price;
        }

        var data1 = {
            "propertyId": 226,
            "examinationNo": "",
            // "reasonId": parseInt(reason),
            "reasonId": reason,
            "requestTypeId": parseInt(requestType),
            "certificateType": parseInt(certificateTypeId),
            "requestTimezone": "Asia/Kolkata",
            "items": item,
            "price": parseInt(totalCount)
        }
        localStorage.setItem("valueprosData", JSON.stringify(data1))
        navigate("/item-list");

    }

    const delImg = (i) => {
        console.log(imgSrcUrl)

        var src = [...imgSrcUrl]
        // console.log(i)
        src.splice(i, 1);
        // console.log(src)
        // setimgSrcUrl([])
        setimgSrcUrl(src);

        // console.log(imgSrcUrl)
        setimgCount('');
        if (imgSrcUrl.length == 0) {
            setValue(false)
        }
    }

    const uploadBanner = (event) => {
        const ban = event.target.files
        const img = event.target.files[0].name
        uploadedFiles.push(img)
        console.log(uploadedFiles)
        const reader = new FileReader();
        reader.readAsDataURL(ban[0]);
        reader.onload = () => {
            const fileName = new FormData();
            fileName.append("file", ban[0]);
            console.log(imgSrcUrl.length);
            if (imgSrcUrl.length >= maxImageSelect) {
                setimgCount('Please add atleast 3 images and maximum of 5 images.')
                return false
            } else {
                uploadFile(fileName).then(res => {
                    if (res.success) {
                        imgSrc.push(res.data.src);
                        console.log(res.data.src)

                        var imgUrl = res.data.src
                        var data = {
                            "imagePath": imgUrl,
                            "type": "pic",
                            "description": ""
                        }
                        setimgSrcUrl(arr => [...arr, data])
                        // setimgSrcUrl(arr => [...arr, res.data.src])
                        // setTimeout(() => {
                        //     console.log(imgSrcUrl)
                        // }, 5000);

                        setValue(true);
                        setimgCount('');
                        toast.success("Image uploaded successfully");
                    } else {
                        toast.error("Image upload didn't successfull");
                        if (res.code == 401) {
                            localStorage.clear()
                            let path = `/`
                            navigate(path)
                            setTimeout(() => {
                                toast.error("Sorry, Session Expired");
                            }, 1000);
                        }
                    }
                })
            }
        }
    }

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">
                                {
                                    valAppraisal ?
                                        <div>
                                            <div className='col-md-12'>
                                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}><b style={{ color: '#E74C3C' }}>*</b>Please select your desired Appraisal Service.</h5>
                                                <select className="form-control" value={requestTypeId} onChange={(e) => { setrequestTypeId(e.target.value) }}>
                                                    <option value={""}>Please select one ...</option>
                                                    <option value={9001}>Valuation Certificate - $75 per item</option>
                                                    <option value={9002}>Appraisal Report - $125 per item</option>
                                                </select>
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{reqTypenotify}</div>
                                            </div>

                                            {
                                                requestTypeId == 9001 || requestTypeId == '' ?
                                                    null :
                                                    <div>
                                                        <div className='col-md-12'>
                                                            <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}><b style={{ color: '#E74C3C' }}>*</b>Please select a single reason for this appraisal.</h5>
                                                            <select className="form-control" onChange={(e) => { setReason(e.target.value) }} value={reason}>
                                                                <option value={""}>Please select one ...</option>
                                                                {
                                                                    reasonData.map((item, key) => (
                                                                        <option value={item.id} key={key}>{item.value}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{reasonNotify}</div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Please provide the Client's full name and address.</h5>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Full Name (individual or entity)</h5>
                                                            <input className='form-control' type="text" onChange={(e) => { setfname(e.target.value) }} />
                                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{fnamenotify}</div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Address (required for USPAP Certification - report is emailed)</h5>
                                                            <input className='form-control' type="text" onChange={(e) => { setaddress(e.target.value) }} />
                                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{addressnotify}</div>
                                                        </div>
                                                    </div>
                                            }

                                            {editFlag ?
                                                <div className='col-md-12 d-flex justify-content-center'>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {/* onClick={checkOut} */}
                                                            <div className='checkOut' style={{ cursor: "pointer" }} onClick={nextEditTable}>
                                                                <h5 className='mt-2'>Next&nbsp;&nbsp;
                                                                    <img width="40" src={imageName.getstart}></img></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='col-md-12 d-flex justify-content-center'>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {/* <div className='checkOut' style={{ cursor: "pointer" }} onClick={checkOut}>
                                                                <h5 className='mt-2'>Checkout&nbsp;&nbsp;
                                                                    <img width="40" src={imageName.getstart}></img></h5>
                                                            </div> */}
                                                            <div className='checkOut' style={{ cursor: 'pointer' }} onClick={checkOut}>
                                                                <div className='row'>
                                                                    <div className="col-md-12">
                                                                        <h5 className='mt-2'>Checkout<img width='40' src={imageName.getstart}></img></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div> :
                                        <div id="block1">
                                            <div className='col-md-12'>
                                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}><b style={{ color: '#E74C3C' }}>*</b>What is your item called?</h5>

                                                <input className='form-control' type="text" value={itemName} onChange={(e) => setitemName(e.target.value)} />
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{itemnotify}</div>
                                            </div>
                                            <div className='col-md-12'>
                                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}><b style={{ color: '#E74C3C' }}>*</b>Please select the category that best describes your items.</h5>

                                                <select onChange={(e) => { setDynamicForm(e) }} value={category} className="form-control">
                                                    <option value="">Please select category ...</option>
                                                    {
                                                        catData.map((item, i) => (
                                                            <option key={item.title} value={item.value}>{item.title}</option>
                                                        ))
                                                    }
                                                </select>
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{categorynotify}</div>

                                                {dynamicArr.map((item, i) =>

                                                    <div key={i}>
                                                        <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>{item.required ? <b style={{ color: '#E74C3C' }}>*</b> : null}{item.title}</h5>
                                                        {
                                                            item.type === 'select' ?
                                                                <div key={i}>
                                                                    <select className="form-control mt-3"
                                                                        name={item.title} value={item.selected == null ? "" : item.selected} onChange={event => handleFormChange(i, event)} >
                                                                        <option>Select</option>
                                                                        {
                                                                            item.options.map((opt, i) => (
                                                                                <option key={i}>
                                                                                    {opt.title}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                                :
                                                                <div key={i}>
                                                                    <input type={item.type} className="form-control mt-3"
                                                                        value={item.selected == null ? "" : item.selected}
                                                                        onChange={event => handleFormChange(i, event)} />
                                                                </div>
                                                        }
                                                        {errorindex == i ?
                                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{errorMessage}</div> :
                                                            null
                                                        }
                                                    </div>
                                                )
                                                }
                                            </div>

                                            <div className='col-md-12'>
                                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}><b style={{ color: '#E74C3C' }}>*</b>Please upload a minimum of 3 images from your camera gallery or files. Note if there are any dates, names, etchings, signature, company names, or other unique characteristics, please provide an image.</h5>

                                                <div className='row d-flex justify-content-center'>
                                                    <div className='col-md-11 mt-3'>
                                                        <input id='inputFile' type='file' multiple
                                                            accept='image/*'
                                                            onChange={uploadBanner}
                                                            style={{ display: "None" }}
                                                        />
                                                        <div id='uploadImg' className='row uploadspace' onClick={clickImage}>
                                                            <div className="col-md-2 cambg">
                                                                    <img src={imageName.camera} width="50"></img>
                                                            </div>
                                                            <div className="col-md-10 mt-4">
                                                                <label style={{ marginTop: '10px', fontSize: '25px', color: '#c5c5c5' }}><img src={imageName.cloud} width="50"></img>&nbsp;
                                                                <b style={{ color: '#2E86C1' }}>Click here to upload images or Browse</b>
                                                                </label>

                                                                {/* <a className={`btn btn-primary ${!fileLimit ? '' : 'disabled'} `} style={{color: '#ffffff'}}>Upload Files</a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'center', fontWeight: '600' }}>{imgCount}</div>
                                            </div>



                                            <div style={{ display: 'inline-flex' }}>
                                                {

                                                    value ? imgSrcUrl.map((item, i) => (
                                                        <div className='mt-2' key={i}>
                                                            <div style={{ margin: '10px' }}>
                                                                <span ><img className='delIcon' src={imageName.deleteicon} onClick={() => delImg(i)} style={{ cursor: 'pointer' }}></img></span><br></br>
                                                                <img
                                                                    className='imgItem'
                                                                    src={item.imagePath}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    )) : null
                                                }
                                            </div>

                                            <div className='col-md-12 d-flex justify-content-center'>
                                                <div className="row">
                                                    {!editFlag ? <>
                                                        <div className="col-md-5">
                                                            {/* <div className='getStart' style={{ cursor: "pointer" }} onClick={moreData}>
                                                                <h5 className='mt-2'>Add More Item&nbsp;&nbsp;
                                                                    <img width="40" src={plus}></img></h5>
                                                            </div> */}
                                                            <div className='addMore' style={{ cursor: 'pointer' }} onClick={moreData}>
                                                                <div className='row'>
                                                                    <div className="pl-2">
                                                                        <h5 className='mt-2'>Add another item<img width='40' src={plus}></img></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            {/* <div className='checkOut' style={{ cursor: "pointer" }} onClick={goAppraisal}>
                                                                <h5 className='mt-2'>Next&nbsp;&nbsp;
                                                                    <img width="40" src={imageName.getstart}></img></h5>
                                                            </div> */}
                                                            <div className='nextbtn' style={{ cursor: 'pointer' }} onClick={goAppraisal}>
                                                                <div className='row'>
                                                                    <div className="pl-2">
                                                                        <h5 className='mt-2'>Next<img width='40' src={imageName.getstart}></img></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> </> :
                                                        <div className="col-md-5">
                                                            {/* <div className='checkOut' style={{ cursor: "pointer" }} onClick={nextEditTable}>
                                                                <h5 className='mt-2'>Update&nbsp;&nbsp;
                                                                    <img width="40" src={imageName.getstart}></img></h5>
                                                            </div> */}
                                                            <div className='checkOut' style={{ cursor: 'pointer' }} onClick={nextEditTable}>
                                                                <div className='row'>
                                                                    <div className="col-md-12">
                                                                        <h5 className='mt-2'>Update<img width='40' src={imageName.getstart}></img></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                }

                                {/* <div className='col-md-12 d-flex justify-content-center'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className='getStart' style={{ cursor: "pointer" }} onClick={showData}>
                                                <h5 className='mt-2'>Add More Item&nbsp;&nbsp;
                                                    <img width="40" src={plus}></img></h5>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='checkOut' style={{ cursor: "pointer" }} onClick={goAppraisal}>
                                                <h5 className='mt-2'>Checkout&nbsp;&nbsp;
                                                    <img width="40" src={imageName.getstart}></img></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )
}

export default Item;