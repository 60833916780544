import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import { getBill } from '../RestAPI/Rest';
import { format } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { LIMIT_ARRAY } from '../constant/ConstValue';

function Billing() {
    const navigate = useNavigate();
    var searchImg = imageName.search
    var billData = []
    const [value, setValue] = useState([]);
    var [res, setRes] = useState({});
    var [listItem, setlistItem] = useState([]);
    var [pageNo, setpageNo] = useState(1)
    var [start, setStart] = useState(1)
    var [end, setEnd] = useState(10)
    var [limit, setLimit] = useState(10)
    var [search, setsearch] = useState('')
    const [limitArr, setLimitArr] = useState([]);
    const [data, setData] = useState([]);
    const [app, setApp] = useState('Appraisal');
    const [valu, setValu] = useState('Valuation');

    const next = () => {
        var page_no = pageNo
        page_no += 1
        setpageNo(page_no)

        var st = parseInt(start)
        st += parseInt(limit)
        setStart(st)

        var nd = parseInt(end)
        nd += parseInt(limit)
        setEnd(nd)

        var data = {
            'pageNo': page_no,
            'limit': limit,
            'search': search
        }
        getBill(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setValue(result.data.data)
        })
    }

    const prev = () => {
        var page_no = pageNo
        page_no -= 1
        setpageNo(page_no)

        var st = start
        st -= limit
        setStart(st)

        var nd = end
        nd -= limit
        setEnd(nd)

        var data = {
            'pageNo': page_no,
            'limit': limit,
            'search': search
        }
        getBill(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setValue(result.data.data)
        })
    }

    const clickPage = (item) => {
        setpageNo(item)

        var st = (limit * item) - (limit - 1)
        var nd = (limit * item)
        setStart(st)
        setEnd(nd)

        var data = {
            'pageNo': item,
            'limit': limit,
            'search': search
        }
        var totalitem = []
        getBill(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setValue(result.data.data)
            for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                totalitem.push(i + 1)

            }
            setlistItem(totalitem)
        })
    }

    const searchReport = (event) => {
        if (search.length >= '3') {
            var data = {
                'pageNo': pageNo,
                'limit': limit,
                'search': search
            }
            var totalitem = []
            getBill(data).then(result => {
                if (result.code == 401) {
                    localStorage.clear()
                    let path = `/`
                    navigate(path)
                    setTimeout(() => {
                        toast.error("Sorry, Session Expired");
                    }, 1000);
                }
                setRes(result.data)
                setValue(result.data.data)
                for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                    totalitem.push(i + 1)

                }
                setlistItem(totalitem)
            })
        }

        if (search.length == '0') {
            var data = {
                'pageNo': pageNo,
                'limit': limit,
                'search': search
            }
            var totalitem = []
            getBill(data).then(result => {
                setRes(result.data)
                setValue(result.data.data)
                for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                    totalitem.push(i + 1)

                }
                setlistItem(totalitem)
            })
        }
    }

    function getBillFun() {
        var data = {
            'pageNo': pageNo,
            'limit': limit,
            'search': search
        }
        var totalitem = []
        getBill(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setValue(result.data.data)
            for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                totalitem.push(i + 1)
            }
            setlistItem(totalitem)
        })
    }

    useEffect(() => {
        getBillFun();
        setLimitArr(LIMIT_ARRAY)
    }, [limit])

    function pageLimitChange(e) {
        setLimit(e.target.value);

        var st = (e.target.value - (e.target.value - 1))
        var nd = (e.target.value)
        setStart(st)
        setEnd(nd)
        setpageNo(1)
    }

    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            let path = `/`
            navigate(path)
        } else {
            getBillFun();
        }

    }, [])

    // const formatdate = (val) => {
    //     let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"]
    //     let nd = new Date(val);
    //     let year = nd.getFullYear();
    //     let month = nd.getMonth();
    //     let day = nd.getDate();
    //     let str = (month+1) + "-" + day + "-" + year;
    //     return str;
    // }

    const formatdate = (val) => {
        let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"]
        let nd = new Date(val);
        // console.log(val)
        let year = nd.getFullYear();
        let month = ("0" + (nd.getMonth() + 1)).slice(-2);
        let day = ("0" + nd.getDate()).slice(-2)
        let str = month + "-" + day + "-" + year;
        return str;
    }

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            {/* <div className="row"> */}
                                <div className="col-md-12 menu-card2">
                                    {/* <div className='row d-flex justify-content-center'>
                                    <div className="col-md-11 mt-3 mb-5">
                                        <div className='row d-flex justify-content-center'>
                                            <div className="col-md-8 searchCss d-flex justify-content-start">
                                                <input type="text" id="" className="form-control" placeholder="Search items ..." aria-label="Search" style={{ borderRadius: '25px', backgroundColor: '#f6f6f6' }} value={search} onChange={(e) => { setsearch(e.target.value) }} onKeyUp={searchReport} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                    <div className='row d-flex justify-content-center'>
                                        <div className="col-md-12 mt-5">
                                            <span style={{ fontSize: "24px", fontWeight: "500" }}>Billing</span>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center mt-3 mb-5'>
                                        <div className='col-md-8'>
                                            {
                                                value.length > 0 ?
                                                    value.map((item, i) =>
                                                        <div className='row billBorder mt-2'>
                                                            <div className='col-md-8'>
                                                                <div className='col-md-12 mt-2'>
                                                                    <h5 style={{ fontSize: "18px", fontWeight: "500", textAlign: "left" }}>
                                                                        {
                                                                            item.requestTypeId == 9002 ?
                                                                                item.certificateType == 7002 ?
                                                                                    "Valuation" : "Appraisal"
                                                                                : "Onsite Appraisal"
                                                                        }
                                                                    </h5>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <h5 style={{ fontSize: "18px", fontWeight: "500", textAlign: "left" }}>{formatdate(item.createdAt)}</h5>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <h5 style={{ fontSize: "18px", fontWeight: "500", textAlign: "left" }}>Paid Via:&nbsp;{item.gateway}</h5>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <br></br>
                                                                <div className='col-md-12 mt-2'>
                                                                    <h5 style={{ fontSize: "24px", fontWeight: "500", textAlign: "left" }}>$&nbsp;{item.amount}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    <div className='row d-flex justify-content-center mt-5'>
                                                        <h5>Transaction not found ...!</h5>
                                                    </div>
                                            }
                                        </div>
                                    </div>


                                    <div className='row d-flex justify-content-center'>
                                        <div className="col-md-11 mb-3">
                                            <div className='row'>
                                                <div className="col-md-12 d-flex justify-content-center">
                                                    <div className="dataTables_length bs-select" id="dtBasicExample_length">
                                                        <label>
                                                            <select name="dtBasicExample_length" aria-controls="dtBasicExample" className="custom-select custom-select-sm form-control form-control-sm" value={limit} onChange={(e) => pageLimitChange(e)}>
                                                                {limitArr.map((item, i) =>
                                                                    <option value={item.value} key={i}>{item.label}</option>
                                                                )}
                                                                {/* <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option> */}
                                                            </select>
                                                        </label>
                                                        &nbsp;&nbsp;

                                                        <span style={{ color: '#848992' }}>

                                                            Showing {start} - {end} of {res.pageNumber}

                                                        </span>


                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-2 d-flex justify-content-center">
                                                    <div className="dataTables_paginate paging_simple_numbers" id="dtBasicExample_paginate">
                                                        <ul className="pagination">
                                                            {
                                                                pageNo == 1 ?
                                                                    <li className="paginate_button page-item previous disabled" id="dtBasicExample_previous">
                                                                        {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="0" tabindex="0" class="page-link">Previous</a> */}
                                                                        <i className="fas fa-chevron-left designarr" style={{ marginRight: "20px", marginTop: "0px" }}></i>
                                                                    </li> : <li className="paginate_button page-item previous disabled" id="dtBasicExample_previous" onClick={prev}>
                                                                        {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="0" tabindex="0" class="page-link">Previous</a> */}
                                                                        <i className="fas fa-chevron-left designarr" style={{ marginRight: "20px", marginTop: "0px" }}></i>
                                                                    </li>
                                                            }

                                                            {
                                                                listItem.map((item, index) =>


                                                                    <li className={"paginate_button page-item " + (pageNo == item ? "active" : "")} key={index} onClick={(e) => clickPage(item)}>
                                                                        <a aria-controls="dtBasicExample" data-dt-idx="2" tabIndex="0" className="page-link">{item}</a>
                                                                    </li>


                                                                )
                                                            }
                                                            {
                                                                pageNo == listItem.length ?
                                                                    <li className="paginate_button page-item next" id="dtBasicExample_next">
                                                                        {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="7" tabindex="0" class="page-link">Next</a> */}
                                                                        <i className="fas fa-chevron-right designarr" style={{ marginLeft: "20px", marginTop: "0px" }}></i>
                                                                    </li> : <li className="paginate_button page-item next" id="dtBasicExample_next" onClick={next}>
                                                                        {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="7" tabindex="0" class="page-link">Next</a> */}
                                                                        <i className="fas fa-chevron-right designarr" style={{ marginLeft: "20px", marginTop: "0px" }}></i>
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default Billing;