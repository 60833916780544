import { PropTypes } from 'prop-types';
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

function ToastContain({
    isHidden
}) {

    return (
        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    )
}


ToastContain.defaultProps = {
    isHidden: false,
};

ToastContain.propTypes = {
    onRequestClose: PropTypes.func
};


export default ToastContain;