import React, { useState, useEffect, boolean } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import { signUp } from '../RestAPI/Rest';
import Login from '../components/Login';
import { handleloginShow } from '../components/Login'
import { logIn, forgotPass, fetchUserDetails, getUserNotif } from '../RestAPI/Rest';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const { useRef } = React

export default function Signup(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [loginshow, setloginShow] = useState(false);
    const [common, setCommon] = useState(true);
    const [value, setValue] = useState("");
    var [userName, setuserData] = useState("");
    var [notifCount, setNotifCount] = useState(0);

    var [loginemail, setloginEmail] = useState('');
    var [forgotemail, setforgotEmail] = useState('');
    var [password, setPassword] = useState('');
    var [check, setCheck] = useState('');
    // const [value, setValue] = useState("");
    var [userName, setuserData] = useState("");
    var [firstName, setfirstName] = useState("");
    const [userInfo, setuserInfo] = useState({});

    const [loginemailnotify, setloginemailnotify] = useState('');
    const [loginpassnotify, setloginpassnotify] = useState('');

    var [fname, setfname] = useState("");
    var [lname, setlname] = useState("");
    var [email, setemail] = useState("");
    var [mobile, setmobile] = useState("");
    var [pass, setpass] = useState("");
    var [cnfpass, setcnfpass] = useState("");

    var [check1, setCheck1] = useState(true);
    var [check2, setCheck2] = useState(true);
    var checkSignUp = false;
    var checkPolicy = false;
    var optMail = false

    const [fnamenotify, setfnamenotify] = useState('');
    const [lnamenotify, setlnamenotify] = useState('');
    const [emailnotify, setemailnotify] = useState('');
    const [mobilenotify, setmobilenotify] = useState('');
    const [passnotify, setpassnotify] = useState('');
    const [cnfpassnotify, setcnfpassnotify] = useState('');
    const [mismatchpassnotify, setmismatchpassnotify] = useState('');
    const [check1notify, setcheck1notify] = useState('');
    const [check2notify, setcheck2notify] = useState('');


    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [cnfvalues, setcnfValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [eyeval, setEyeVal] = React.useState({
        password: "",
        showPassword: false,
    });

    const goToSignUp = () => {
        <Signup />
    }
    const forgotPwd = () => {
        setCommon(false);
    }
    const goadminLogin = () => {
        window.open('http://52.201.119.41:8082/login')
    }
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowCnfPassword = () => {
        setcnfValues({ ...cnfvalues, showPassword: !cnfvalues.showPassword });
    };
    const handleClickEyePassword = () => {
        setEyeVal({ ...eyeval, showPassword: !eyeval.showPassword });
    };

    const handleClose = () => {
        setShow(false);
        setCommon(true)
        setfname('')
        setlname('')
        setpass('')
        setcnfpass('')
        setemail('')
        setmobile('')
        setCheck1(true)
        setCheck2(true)
        checkSignUp = false
        checkPolicy = false
        setfnamenotify('')
        setlnamenotify('')
        setemailnotify('')
        setmobilenotify('')
        setpassnotify('')
        setcnfpassnotify('')
        setmismatchpassnotify('')
        setcheck1notify('')
        setcheck2notify('')
    }

    const handleloginClose = () => {
        setloginShow(false);
        setCommon(true)
        setfname('')
        setlname('')
        setpass('')
        setcnfpass('')
        setemail('')
        setmobile('')
        setloginEmail("")
        setPassword("")
        setCheck1(false)
        setCheck2(true)
        checkSignUp = false
        checkPolicy = false
        setfnamenotify('')
        setlnamenotify('')
        setemailnotify('')
        setmobilenotify('')
        setpassnotify('')
        setcnfpassnotify('')
        setmismatchpassnotify('')
        setcheck1notify('')
        setcheck2notify('')
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // const handleclose = () => {
    //     setAnchorEl(null);
    // };
    const handleShow = () => setShow(true);
    const handleloginShow = () => setloginShow(true);

    const goNotif = () => {
        let path = `/notifications`
        navigate(path)
    }

    const submitSignUp = () => {
        // console.log("SignUp")
        var regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{6,15}$/;
        var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (fname == '') {
            setfnamenotify("Enter first name");
            return false
        } else {
            setfnamenotify('')
        }
        if (lname == '') {
            setlnamenotify("Enter last name");
            return false
        } else {
            setlnamenotify('')
        }
        if (email == '') {
            setemailnotify("Enter email");
            return false
        } else {
            if(!regexEmail.test(email)){
                setemailnotify("Enter valid email");
                return false
            }else{
                setemailnotify('');
            }
        }
        if (mobile == '') {
            setmobilenotify("Enter mobile number");
            return false
        } else {
            setmobilenotify('')
        }
        if (pass != '') {
            if (!regexPass.test(pass)) {
                setpassnotify("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                return false
            } else {
                setpassnotify('')
            }
        }
        if (cnfpass != '') {
            if (!regexPass.test(cnfpass)) {
                setcnfpassnotify("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                return false
            } else {
                setcnfpassnotify('')
            }
        }
        if (pass != cnfpass) {
            setmismatchpassnotify("Password & Confirm Password mismatch");
            return false
        } else {
            setmismatchpassnotify('')
        }
        // if (check1 == false) {
        //     setcheck1notify("Tick sign up checkbox");
        //     return false
        // } else {
        //     setcheck1notify('')
        // }
        // if (check2 == false) {
        //     setcheck2notify("Tick policy checkbox");
        //     return false
        // } else {
        //     setcheck2notify('')
        // }
        if (check1 == true) {
            checkPolicy = true
        } else {
            checkSignUp = false
        }
        if (check2 == true) {
            optMail = true
        } else {
            optMail = false
        }
        // if(fname == '' && lname == '' && pass == '' && cnfpass == '' && email == '' && mobile == '' && check1 == '' && check2 == ''){
        //     setisFill(false);
        //     return false;
        // }

        var info = {
            "firstName": fname,
            "lastName": lname,
            "password": pass,
            "email": email,
            "mobile": mobile.split('-').join(''),
            "signUpCheckBox": false,
            "policyCheck": checkPolicy,
            "optMail": optMail
        }
        console.log(info);

        signUp(info).then(res => {
            if (res.success) {
                setfname('')
                setlname('')
                setpass('')
                setcnfpass('')
                setemail('')
                setmobile('')
                setCheck1(true)
                setCheck2(true)
                checkSignUp = false
                checkPolicy = false
                setfnamenotify('')
                setlnamenotify('')
                setemailnotify('')
                setmobilenotify('')
                setpassnotify('')
                setcnfpassnotify('')
                setmismatchpassnotify('')
                setcheck1notify('')
                setcheck2notify('')
                handleClose()
                let path = `/verify-email`
                navigate(path)
                props.onLoginCheck(true);
                // toast.success("Your account has been created successfully.");
            } else {
                // setfname('')
                // setlname('')
                // setpass('')
                // setcnfpass('')
                // setemail('')
                // setmobile('')
                // setCheck1(true)
                // setCheck2(true)
                // checkSignUp = false
                // checkPolicy = false
                // setfnamenotify('')
                // setlnamenotify('')
                // setemailnotify('')
                // setmobilenotify('')
                // setpassnotify('')
                // setcnfpassnotify('')
                // setmismatchpassnotify('')
                // setcheck1notify('')
                // setcheck2notify('')
                // toast.error("Account did not created successfully");
                toast.error("Your account was not created successfully.");
            }
        })
    }
    function handleCheckboxClick(event) {
        console.log(event.target.value)
        // if (!disableInputIsChecked) { // this click was to check the box
        //     clearInput();
        // }
        // setDisableInputIsChecked(prevValue => !prevValue); // invert value
    }

    function getUserDetails() {
        fetchUserDetails().then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            console.log(result)
            setuserInfo(result.data)
            setfirstName(result.data.firstName)
        })
    }

    const submitThis = () => {
        if (loginemail == '') {
            setloginemailnotify('Please enter email')
            return false;
        } else {
            setloginemailnotify('')
        }
        if (password == '') {
            setloginpassnotify('Please enter password')
            return false;
        } else {
            setloginpassnotify('')
        }

        if (check == '') {
            check = false;
        } else if (check == 'true') {
            check = true;
        } else if (check == 'false') {
            check = false;
        }
        var info = { "email": loginemail, "password": password, "rememberMe": check };

        logIn(info).then(res => {

            if (res.success) {
                toast.success("Successfully Logged In")
                localStorage.setItem("userInfo", JSON.stringify(res));
                handleloginClose()
                setValue(true)
                props.onLoginCheck(true);
                getUserDetails()
                setloginEmail("")
                setPassword("")
                setCheck("")
            } else {
                toast.error("Invalid Email or Password")
                // setloginEmail("")
                // setPassword("")
                setCheck("")
            }
            let path = `/`
            navigate(path)
        })
    }

    const submitForgot = () => {
        var info = { "email": forgotemail };
        // console.log(info);

        forgotPass(info).then(res => {
            handleloginClose()
            setCommon(true)
            setforgotEmail("")
            toast.success(res.message);
            let path = `/`
            navigate(path)
        })
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + "-" + match[2] + "-" + match[3];
        } else {
            let newPhNum = "";
            for (let i = 0; i < cleaned.length; i++) {
                if (i === 3 || i === 6) {
                    newPhNum = newPhNum + "-";
                } else if (i >= 10) {
                    break;
                }
                newPhNum = newPhNum + cleaned.substr(i, 1);
            }
            return newPhNum;
        }
    }

    function getNotif() {
        var data = {
            'pageNo': 1,
            'limit': 20
        }

        getUserNotif(data).then(result => {
            var notiCount = 0;

            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (result.success) {
                for (let i = 0; i < result.data.data.length; i++) {
                    if (result.data.data[i].isSeen == false) {
                        notiCount++;
                    }
                }
            }
            setNotifCount(notiCount);
        })
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("userInfo"))) {
            setValue(true)
            props.onLoginCheck(true);
            setuserData(JSON.parse(localStorage.getItem("userInfo")).data.user.firstName)
            getUserDetails()
            getNotif()
        } else {
            setValue(false)
            props.onLoginCheck(false);
        }


    }, [])

    return (
        <>
            {/* {
            console.log("--------->",props.loginCheck, value)
        } */}
            {
                props.loginCheck ?
                    <div className='col-md-8 bell d-flex justify-content-start'>
                        <a href="" class="text-dark" onClick={goNotif}>
                            <i class="fas fa-bell fa-2x"></i>
                            {
                                notifCount > 0 ?
                                    <span class="badge rounded-pill badge-notification bg-danger">{notifCount}</span> :
                                    null
                            }
                        </a>
                        {/* <h6><img className='d-flex justify-content-start notif' src={imageName.notification} style={{ cursor: 'pointer' }} width='30' onClick={goNotif}></img><span class="badge badge-secondary">1</span></h6> */}
                    </div>
                    :
                    <div className='col-md-8'>
                        <h5 className='login' onClick={handleloginShow} style={{ cursor: 'pointer' }}><b>LOGIN</b></h5>
                        <h5 className='createAccTxt' style={{ cursor: 'pointer' }} onClick={handleShow}>Create an Account</h5>
                    </div>
            }


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="container">
                        <div className='verticalAlign'>
                            <h2 className='logotxt1'>
                                <img className='logo' src={imageName.logo} />
                            </h2>
                            <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "600" }}>
                                <label >Your single valuation source for all assets</label>
                                <br></br>
                                <label>Create your free account now</label>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <label>*First Name:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={fname}
                                        onChange={(e) => setfname(e.target.value)}
                                    />
                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{fnamenotify}</div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <label>*Last Name:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={lname}
                                        onChange={(e) => setlname(e.target.value)}
                                    />
                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{lnamenotify}</div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <label>*Email:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{emailnotify}</div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <label>*Mobile Number<span style={{ fontSize: "12px" }}> (We may text you about an item, if necessary.)</span></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formatPhoneNumber(mobile)}
                                        onChange={(e) => setmobile(e.target.value)}
                                    />
                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{mobilenotify}</div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <label>*Password:</label>
                                    <p style={{ fontSize: '12px', color: '#275F98' }}>Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character</p>
                                    <div className='row'>
                                        <div className="col-md-12 passinp">
                                            <input id="password"
                                                className="form-control"
                                                type={values.showPassword ? "text" : "password"}
                                                placeholder=""
                                                name="password"
                                                value={pass}
                                                onChange={(e) => setpass(e.target.value)}
                                            />
                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{passnotify}</div>
                                        </div>
                                        <div className="eyecss">
                                            {

                                                values.showPassword ?
                                                    <span onClick={handleClickShowPassword} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickShowPassword} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <label>*Confirm Password:</label>
                                    <div className='row'>
                                        <div className="col-md-12 passinp">
                                            <input id="password1"
                                                className="form-control"
                                                type={cnfvalues.showPassword ? "text" : "password"}
                                                placeholder=""
                                                name="password"
                                                value={cnfpass}
                                                onChange={(e) => setcnfpass(e.target.value)}
                                            />
                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{cnfpassnotify}</div>
                                        </div>
                                        <div className="eyecss">
                                            {

                                                cnfvalues.showPassword ?
                                                    <span onClick={handleClickShowCnfPassword} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickShowCnfPassword} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{mismatchpassnotify}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <Form>
                                        <label>*Required fields</label>
                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                // value={true}
                                                onChange={(e) => setCheck1(e.target.checked)}
                                                checked={check1}
                                                // onChange ={handleCheckboxClick}
                                                label="By signing up for this app, you agree to our Privacy Policy, Terms and Conditions and Acceptable Use Policy."
                                            />
                                        </Form.Group>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{check2notify}</div>
                                            </div>
                                        </div>

                                        <br></br>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                value={true}
                                                // onChange={(e) => setCheck1(e.target.value)}
                                                onChange={(e) => setCheck2(e.target.checked)}
                                                checked={check2}
                                                label="Check here to receive emails with special offers, application updates, and relevant content. You
                                                may unsubscribe at any time."
                                            />
                                        </Form.Group>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{check1notify}</div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>

                                <div className='col-md-12 mb-3'>
                                    {
                                        (fname && lname && email && mobile && pass && cnfpass && check1) ?
                                            <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px', cursor: 'pointer' }}>
                                                <Button className='btn btnCreateAcc' type='submit' onClick={submitSignUp}>Create Account</Button>
                                            </div> :
                                            <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                                <Button className='btn btnCreateAcc1' type='submit' disabled>Create Account</Button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>




            <Modal show={loginshow} onHide={handleloginClose} centered>
                <Modal.Body>
                    {
                        common ? <div className="container">
                            <div className='verticalAlign'>
                                <h2 className='logotxt1'>
                                    <img className='logo' src={imageName.logo} />
                                </h2>
                                <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "600" }}>
                                    <label >Your single valuation source for all assets.</label>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mb-3'>
                                        <span><b>Email:</b></span>
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder=""
                                            value={loginemail}
                                            onChange={(e) => setloginEmail(e.target.value)}
                                        />
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{loginemailnotify}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mb-3 passinp'>
                                        <span><b>Password:</b></span>
                                        <input
                                            className="form-control"
                                            type={eyeval.showPassword ? "text" : "password"}
                                            placeholder=""
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{passnotify}</div>
                                            </div>
                                        </div>
                                        <div className="eyecss1">
                                            {

                                                eyeval.showPassword ?
                                                    <span onClick={handleClickEyePassword} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickEyePassword} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                            }
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                value={true}
                                                onChange={(e) => setCheck(e.target.value)}
                                                label="Keep me logged in"
                                            />
                                        </Form.Group>

                                        <div className='col-md-12 text-center' style={{ marginTop: '25px', paddingBottom: '30px' }}>
                                            <div className='row'>
                                                {
                                                    (loginemail != '' && password != '') ?

                                                        <div className='col-md-5 btnLogin1' onClick={submitThis}>
                                                            <span>Login</span>
                                                        </div> :
                                                        <div className='col-md-5 btnLogin' onClick={submitThis}>
                                                            <span>Login</span>
                                                        </div>
                                                }
                                                {/* <Button className='btn btnLogin' type='button' onClick={submitThis}>Login</Button> */}
                                                <div className='col-md-2'></div>
                                                <div className='col-md-5 btnCreateAcc' onClick={goToSignUp}>
                                                    <span>Create Account</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-md-12' onClick={forgotPwd}>
                                        <h5 style={{ textAlign: 'center', fontSize: '15px', cursor: 'pointer' }}>Forgot Your Password ?</h5>
                                    </div>

                                    <div className='col-md-12 text-center' style={{ marginTop: '20px', paddingBottom: '30px' }} onClick={goadminLogin}>
                                        <Button className='btn btnCreateAcc' type='button' >Administrator/Appraiser Login</Button>
                                    </div>
                                    {/* onClick={goAdminLogin} */}
                                </div>
                            </div>
                        </div> : <div className="container">
                            <div className='verticalAlign'>
                                <h2 className='logotxt1'>
                                    <img className='logo' src={imageName.logo1} />
                                </h2>
                                <div style={{ textAlign: "center", fontWeight: "600", fontSize: '24px' }}>
                                    <label>Password Reset</label>
                                </div>
                                <div className='mb-2' style={{ textAlign: "center", fontWeight: "600", fontSize: '18px' }}>
                                    <label>To reset your password, please enter your email below:</label>
                                </div>
                                <div>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <input
                                                className="form-control"
                                                type="email"
                                                placeholder="name@example.com"
                                                value={forgotemail}
                                                onChange={(e) => setforgotEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                        <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                            <Button className='btn btnCreateAcc' type='button' onClick={submitForgot}>Send Email</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    }

                </Modal.Body>
            </Modal>



            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}