import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import Header from './Header';
import '../RestAPI/Rest';
import { ChangePass, fetchUserDetails, resetPass, updUserDetails, uploadFile } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


export default function AccountSetting() {
    const navigate = useNavigate();

    var [id, setId] = useState('');
    var [email, setEmail] = useState('');
    var [fname, setFname] = useState('');
    var [lname, setLname] = useState('');
    var [oldPass, setOldPass] = useState('');
    var [newPass, setNewPass] = useState('');
    var [cnfPass, setCnfPass] = useState('');
    var [profileImg, setprofileImg] = useState('');
    var [userName, setuserName] = useState("");
    var [isEdit, setisEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [userData, setuserData] = useState({});

    var [oldPassNotif, setoldPassNotif] = useState('');
    var [newPassNotif, setnewPassNotif] = useState('');
    var [cnfPassNotif, setcnfPassNotif] = useState('');
    var [oldPassValNotif, setoldPassValNotif] = useState('');
    var [newPassValNotif, setnewPassValNotif] = useState('');
    var [cnfPassValNotif, setcnfPassValNotif] = useState('');
    var [newcnfPassNotif, setnewcnfPassNotif] = useState('');
    var [oldnewsamePassNotif, setoldnewsamePassNotif] = useState('');
    var [invalidCurrPassNotif, setinvalidCurrPassNotif] = useState('');


    var uploadedFiles = []

    const [oldValues, setoldValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [newValues, setnewValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [cnfValues, setcnfValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const handleClickOldPass = () => {
        setoldValues({ ...oldValues, showPassword: !oldValues.showPassword });
    };
    const handleClickNewPass = () => {
        setnewValues({ ...newValues, showPassword: !newValues.showPassword });
    };
    const handleClickCnfPass = () => {
        setcnfValues({ ...cnfValues, showPassword: !cnfValues.showPassword });
    };

    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            let path = `/`
            navigate(path)
        } else {
            setuserName(JSON.parse(localStorage.getItem("userInfo")).data.user.firstName)
            fetchUserDetails().then(result => {
                if (result.code == 401) {
                    localStorage.clear()
                    let path = `/`
                    navigate(path)
                    setTimeout(() => {
                        toast.error("Sorry, Session Expired");
                    }, 1000);
                }
                console.log(result)
                setuserData(result.data)
                setId(result.data.id)
                setFname(result.data.firstName)
                setLname(result.data.lastName)
                setEmail(result.data.email)
                setprofileImg(result.data.profileImage)
            })
        }
    }, [])

    const handleClose = () => {
        setShow(false);
        setOldPass("")
        setNewPass("")
        setCnfPass("")
        setoldPassNotif('')
        setnewPassNotif('')
        setcnfPassNotif('')
        setoldnewsamePassNotif('')
        setnewcnfPassNotif('')
        setinvalidCurrPassNotif('')
        setoldPassValNotif('')
        setnewPassValNotif('')
        setcnfPassValNotif('')
        setoldValues({ showPassword: false });
        setnewValues({ showPassword: false });
        setcnfValues({ showPassword: false });
    }
    const handleShow = () => setShow(true);



    const Edit = () => {
        if (isEdit == false) {
            setisEdit(true)
        } else {
            setisEdit(false)
        }
    }

    const updPass = () => {
        var regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{6,15}$/;
        if (oldPass == '') {
            setoldPassNotif("Enter current password");
            setnewPassNotif('')
            setcnfPassNotif('')
            setoldnewsamePassNotif('')
            setnewcnfPassNotif('')
            setinvalidCurrPassNotif('')
            setoldPassValNotif('')
            setnewPassValNotif('')
            setcnfPassValNotif('')
            return false;
        } else {
            // if (oldPass != '') {
            //     if (!regexPass.test(oldPass)) {
            //         setoldPassValNotif("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
            //         setoldPassNotif('')
            //         return false
            //     } else {
            //         setoldPassValNotif('')
            //     }
            // }
            setoldPassNotif('')
        }
        if (newPass == '') {
            setnewPassNotif("Enter new password");
            setoldPassNotif('')
            setcnfPassNotif('')
            setoldnewsamePassNotif('')
            setnewcnfPassNotif('')
            setinvalidCurrPassNotif('')
            setoldPassValNotif('')
            setnewPassValNotif('')
            setcnfPassValNotif('')
            return false;
        } else {
            if (newPass != '') {
                if (!regexPass.test(newPass)) {
                    setnewPassValNotif("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                    setnewPassNotif('')
                    return false
                } else {
                    setnewPassValNotif('')
                }
            }
            setnewPassNotif('')
        }
        if (oldPass == newPass) {
            setoldnewsamePassNotif("Current password and new password can't be same");
            setoldPassNotif('')
            setnewPassNotif('')
            setcnfPassNotif('')
            setnewcnfPassNotif('')
            setinvalidCurrPassNotif('')
            setoldPassValNotif('')
            setnewPassValNotif('')
            setcnfPassValNotif('')
            return false;
        } else {
            setoldnewsamePassNotif('')
        }
        if (cnfPass == '') {
            setcnfPassNotif("Enter confirm password");
            setoldPassNotif('')
            setnewPassNotif('')
            setoldnewsamePassNotif('')
            setnewcnfPassNotif('')
            setinvalidCurrPassNotif('')
            setoldPassValNotif('')
            setnewPassValNotif('')
            setcnfPassValNotif('')
            return false;
        } else {
            if (cnfPass != '') {
                if (!regexPass.test(cnfPass)) {
                    setcnfPassValNotif("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                    setcnfPassNotif('')
                    return false
                } else {
                    setcnfPassValNotif('')
                }
            }
            setcnfPassNotif('')
        }
        if (newPass != cnfPass) {
            setnewcnfPassNotif("New password and confirm password doesn't match");
            setoldPassNotif('')
            setnewPassNotif('')
            setcnfPassNotif('')
            setoldnewsamePassNotif('')
            setinvalidCurrPassNotif('')
            setoldPassValNotif('')
            setnewPassValNotif('')
            setcnfPassValNotif('')
            return false;
        } else {
            setnewcnfPassNotif('')
        }

        var info = { "oldPassword": oldPass, "newPassword": newPass };

        ChangePass(info).then(res => {
            if (res.success) {
                toast.success("Password changed successfully");
                handleClose()
                setOldPass("")
                setNewPass("")
                setCnfPass("")
                let path = `/account-setting`
                navigate(path)
            } else {
                setinvalidCurrPassNotif("Invalid current password.")
            }
        })
    }

    const updateAccSett = () => {
        var profImg
        if (JSON.parse(localStorage.getItem("userInfo")).data.user.profileImage == '' || JSON.parse(localStorage.getItem("userInfo")).data.user.profileImage == undefined) {
            profImg = null
        } else {
            profImg = JSON.parse(localStorage.getItem("userInfo")).data.user.profileImage
        }

        if (fname == undefined || fname == null || fname.length == 0) {
            toast.error("Please enter your first name")
            return false;
        }
        if (lname == undefined || lname == null || lname.length == 0) {
            toast.error("Please enter your last name")
            return false;
        }

        var data = {
            "id": id,
            "email": email,
            "firstName": fname,
            "lastName": lname,
            "profileImage": profImg,
            "countryCode": "+91",
            "address": "",
            "stateId": "",
            "city": "",
            "zip": "",
            "countryId": ""
        }
        // console.log(data)
        updUserDetails(data).then(res => {
            if (res.success) {
                setisEdit(false)
                toast.success(res.message ? res.message : "");
            } else {
                toast.error(res.message ? res.message : "");
            }
            let path = `/account-setting`
            navigate(path)
        })
    }

    function uploadProfile(event) {
        const ban = event.target.files
        const img = event.target.files[0].name
        uploadedFiles.push(img)
        console.log(uploadedFiles)
        const reader = new FileReader();
        reader.readAsDataURL(ban[0]);
        reader.onload = () => {
            const fileName = new FormData();
            fileName.append("file", ban[0]);
            // console.log(fileName)
            uploadFile(fileName).then(res => {
                if (res.success) {
                    // toast.success("Image uploaded successfully");

                    var data = {
                        "id": id,
                        "email": email,
                        "firstName": fname,
                        "lastName": lname,
                        "profileImage": res.data.src,
                        "countryCode": "+91",
                        "address": "",
                        "stateId": "",
                        "city": "",
                        "zip": "",
                        "countryId": ""
                    }
                    console.log(data)
                    updUserDetails(data).then(res => {
                        if (res.success) {
                            fetchUserDetails().then(result => {
                                // console.log(result)
                                setuserData(result.data)
                                setId(result.data.id)
                                setFname(result.data.firstName)
                                setLname(result.data.lastName)
                                setEmail(result.data.email)
                                setprofileImg(result.data.profileImage)
                            })
                            toast.success("Account updation request sent successfully");
                        } else {
                            toast.error("Account updation request didn't send successfully");
                        }
                        let path = `/account-setting`
                        navigate(path)
                    })
                } else {
                    toast.error("Image upload didn't successfull");
                    if (res.code == 401) {
                        localStorage.clear()
                        let path = `/`
                        navigate(path)
                        setTimeout(() => {
                            toast.error("Sorry, Session Expired");
                        }, 1000);
                    }
                }
            })
        }
    }

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">
                                <div className='row'>
                                    <div className="col-md-12">
                                        <h3 className='mt-5'>Your Account Settings</h3>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <input id='file1' type='file' multiple
                                            accept='image/*'
                                            onChange={uploadProfile}
                                            style={{ display: "None" }}
                                        />
                                        <label htmlFor="file1" style={{ cursor: 'pointer' }} >
                                            {
                                                userData.profileImage ?
                                                    <img className='profImg' alt="" src={userData.profileImage}></img> :
                                                    <div className='accProf'>
                                                        <h3 style={{ fontSize: '48px' }}>{userName.charAt(0)}</h3>
                                                    </div>
                                            }
                                        </label>
                                    </div>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <div className='mt-4'>
                                            <button className='updpwdbtn' onClick={handleShow}>Update Password</button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    isEdit ? <div className='row d-flex justify-content-center'>
                                        <div className="col-md-6 upd-details mt-5 mb-5">
                                            <div className='row'>
                                                <div className='col-md-12 text-right'>
                                                    {
                                                        isEdit ? <button className="edit_btn_icon_active" onClick={Edit}><img src={imageName.pencil} width='25'></img></button> : <button className="edit_btn_icon" onClick={Edit}><img src={imageName.pencil} width='25'></img></button>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <span className='mb-2' style={{ fontWeight: "600", textAlign: 'left' }}>First Name</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    value={fname}
                                                    onChange={(e) => setFname(e.target.value)}
                                                />
                                            </div>
                                            <div className='row mt-4'>
                                                <span className='mb-2' style={{ fontWeight: "600", textAlign: 'left' }}>Last Name</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    value={lname}
                                                    onChange={(e) => setLname(e.target.value)}
                                                />
                                            </div>
                                            <div className='row mt-4'>
                                                <span className='mb-2' style={{ fontWeight: "600", textAlign: 'left' }}>Email</span>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder=""
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    disabled
                                                />
                                            </div>

                                            <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                                <Button className='btn btnUpd' type='button' onClick={updateAccSett}>Update</Button>
                                            </div>
                                        </div>
                                    </div> : <div className='row d-flex justify-content-center'>
                                        <div className="col-md-6 details mt-5 mb-5">
                                            <div className='row'>
                                                <div className='col-md-12 text-right' onClick={Edit}>
                                                    <button className="edit_btn_icon"><img src={imageName.pencil} width='25'></img></button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <span style={{ fontWeight: "600" }}>First name</span>
                                            </div>
                                            <div className='row'>
                                                <span>{userData.firstName}</span>
                                            </div>
                                            <div className='row mt-2'>
                                                <span style={{ fontWeight: "600" }}>Last name</span>
                                            </div>
                                            <div className='row'>
                                                <span>{userData.lastName}</span>
                                            </div>
                                            <div className='row mt-2'>
                                                <span style={{ fontWeight: "600" }}>Email</span>
                                                <span></span>
                                            </div>
                                            <div className='row'>
                                                <span>{userData.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>


                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Body>
                                <div className="container">
                                    <div className='verticalAlign'>
                                        <h3 style={{ textAlign: "center" }}>
                                            Update Password
                                        </h3>

                                        <div>
                                            <Form>
                                                <Form.Group className="mt-3 mb-3">
                                                    <Form.Label>*Current Password</Form.Label>
                                                    <input
                                                        className="form-control"
                                                        type={oldValues.showPassword ? "text" : "password"}
                                                        placeholder=""
                                                        value={oldPass}
                                                        onChange={(e) => setOldPass(e.target.value)}
                                                    />
                                                    <div className="eyecss2">
                                                        {

                                                            oldValues.showPassword ?
                                                                <span onClick={handleClickOldPass} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickOldPass} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                                        }
                                                    </div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{oldPassNotif}</div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{invalidCurrPassNotif}</div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{oldPassValNotif}</div>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>*New Password</Form.Label>
                                                    <input
                                                        className="form-control"
                                                        type={newValues.showPassword ? "text" : "password"}
                                                        placeholder=""
                                                        value={newPass}
                                                        onChange={(e) => setNewPass(e.target.value)}
                                                    />
                                                    <div className="eyecss2">
                                                        {

                                                            newValues.showPassword ?
                                                                <span onClick={handleClickNewPass} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickNewPass} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                                        }
                                                    </div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{newPassNotif}</div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{oldnewsamePassNotif}</div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{newPassValNotif}</div>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>*Confirm Password</Form.Label>
                                                    <input
                                                        className="form-control"
                                                        type={cnfValues.showPassword ? "text" : "password"}
                                                        placeholder=""
                                                        value={cnfPass}
                                                        onChange={(e) => setCnfPass(e.target.value)}
                                                    />
                                                    <div className="eyecss2">
                                                        {

                                                            cnfValues.showPassword ?
                                                                <span onClick={handleClickCnfPass} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickCnfPass} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                                        }
                                                    </div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{cnfPassNotif}</div>
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{cnfPassValNotif}</div>
                                                </Form.Group>
                                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{newcnfPassNotif}</div>
                                                <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                                    <Button className='btn btnCreateAcc' type='button' onClick={updPass}>Update</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );
}