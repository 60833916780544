import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Login from './Login'
import Header from './Header'
import { getCatOpt, uploadFile, getReason, addExamination } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { MdEditNote, MdDelete } from "react-icons/md";

function ItemList() {
    const navigate = useNavigate();
    const [itemData, setItemData] = useState([])
    const [totalData, settotalData] = useState({})
    const [itemPrice, setitemPrice] = useState(0)
    const [itemCount, setitemCount] = useState(0)
    const [total, setTotal] = useState(0)
    const [loader, setLoader] = useState(false)
    const plus = require('../../assets/image/additem.png')

    const delItem = async (item, i) => {
        await loaderUpdate(true);
        itemData.splice(i, 1);
        var item_data = JSON.parse(localStorage.getItem("valueprosData"))
        item_data.items = itemData;
        item_data.price = parseInt(item_data.price) - parseInt(item.price);
        setitemPrice(item_data.price);
        toast.success("Item removed");
        localStorage.setItem("valueprosData", JSON.stringify(item_data));
        setItemData(itemData);
        settotalData(JSON.parse(localStorage.getItem("valueprosData")));
        setitemCount(itemData.length);
        await loaderUpdate(false);
    }

    const loaderUpdate = async (type) => {
        setLoader(type);
    }
    function goToItem(item, i) {
        localStorage.setItem("EditItemData", JSON.stringify(item))
        let path = `/item/edit/` + i
        navigate(path)
    }

    const modifyItemData = (modItemsData) => {
        for (let i = 0; i < modItemsData.length; i++) {
            let tempDynamicData = [];
            for (let j = 0; j < modItemsData[i].dynamicForm.length; j++) {
                tempDynamicData.push({ "title": modItemsData[i].dynamicForm[j].title, "value": modItemsData[i].dynamicForm[j].value });
            }
            modItemsData[i].dynamicForm = tempDynamicData;
        }
        return (modItemsData);
    }

    function goToPay() {
        var data1 = {
            "propertyId": 226,
            "examinationNo": "",
            // "reasonId": parseInt(reason),
            "reasonId": null,
            "requestTypeId": parseInt(totalData.requestTypeId),
            "certificateType": parseInt(totalData.certificateType),
            "requestTimezone": "Asia/Kolkata",
            // "items": totalData.items,
            "items": modifyItemData(totalData.items),
            "price": parseInt(totalData.price)
        }

        addExamination(data1).then(res => {
            if (res.success) {
                // setItemAddResponse(res)
                toast.success("Examination request processed successfully.");

                var pay = {
                    "examinationNo": res.data.examinationNo,
                    "id": res.data.id,
                    "itemCount": itemCount,
                    "total": totalData.price,
                    "price": (totalData.price / itemCount)
                }

                localStorage.setItem("payInfo", JSON.stringify(pay));
                setTimeout(() => {
                    let path = `/payment`
                    navigate(path)
                }, 1000);
            } else {
                toast.error("Examination request didn't processed successfully.");
                if (res.code == 401) {
                    localStorage.clear()
                    let path = `/`
                    navigate(path)
                    setTimeout(() => {
                        toast.error("Sorry, Session Expired");
                    }, 1000);
                }
            }
        })
    }

    useEffect(() => {
        var item_data = JSON.parse(localStorage.getItem("valueprosData")).items
        setitemCount(item_data.length)
        settotalData(JSON.parse(localStorage.getItem("valueprosData")))
        setitemPrice(JSON.parse(localStorage.getItem("valueprosData")).price)
        setItemData(item_data);
    }, [])

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">
                                <div className='row d-flex justify-content-start mt-2'>
                                    <div className='col-md-12 mt-3 d-flex justify-content-center'>
                                        <label style={{ fontSize: "20px", fontWeight: "500" }}>Items Added</label>
                                    </div>
                                </div>

                                <div className='row d-flex justify-content-center mt-2 mb-3'>
                                    <div className="col-md-11 table-responsive" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="col-md-12 px-0">
                                            <div className="tblHead">
                                                <div className='firstcol col-md-3' style={{ width: '25%' }}>
                                                    <span>Name</span>
                                                </div>
                                                <div className='firstcol col-md-3' style={{ width: '25%' }}>
                                                    <span>Type</span>
                                                </div>
                                                <div className='firstcol col-md-3' style={{ width: '25%' }}>
                                                    <span>Amount</span>
                                                </div>
                                                <div className='firstcol col-md-3' style={{ width: '25%' }}>
                                                    <span>Edit</span>
                                                </div>
                                            </div>
                                            {loader ? null :
                                                <React.Fragment>
                                                    <table className="table dashtable">
                                                        <tbody>
                                                            {
                                                                itemData.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td style={{ width: '25%' }}>{item.itemName}</td>
                                                                        <td style={{ width: '25%' }}>{totalData.certificateType == 7001 ? 'Appraisal' : 'Valuation'}</td>
                                                                        <td style={{ width: '25%' }}>{"$ " + item.price}</td>
                                                                        <td align='center' style={{ width: '25%' }}>
                                                                            <MdEditNote size={25} onClick={() => goToItem(item, index)} style={{ cursor: 'pointer' }} />
                                                                            &nbsp;&nbsp;
                                                                            <MdDelete size={25} onClick={() => delItem(item, index)} style={{ cursor: 'pointer' }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className='firstcol'>
                                                        <span style={{ fontSize: "18px", fontWeight: "500" }}>Total Amount : $ {itemPrice.toFixed(2)}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>


                                    </div>
                                </div>

                                <div className='row d-flex justify-content-center mt-2 mb-3'>
                                    <div className='col-md-12 mt-3 d-flex justify-content-center'>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                {/* <div className='getStart' style={{ cursor: "pointer" }} onClick={() => { navigate("/item/add") }}>
                                                <h5 className='mt-2'>Add More Item&nbsp;&nbsp;
                                                    <img width="40" src={plus}></img></h5>
                                            </div> */}

                                                <div className='addMore1' style={{ cursor: 'pointer' }} onClick={() => { navigate("/item/add") }}>
                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <h5 className='mt-2'>Add More Items<img width='40' src={plus}></img></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {itemCount == 0 ?
                                                null :
                                                <div className='mx-3'></div>
                                            }
                                            {itemCount == 0 ?
                                                null :
                                                <div className="col-md-5">
                                                    {/* <div className='proceed' style={{ cursor: "pointer" }} onClick={goToPay}>
                                                    <h5 className='mt-2'>Proceed to Pay&nbsp;&nbsp;
                                                        <img width="40" src={imageName.getstart}></img></h5>
                                                </div> */}
                                                    <div className='proceed' style={{ cursor: 'pointer' }} onClick={goToPay}>
                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <h5 className='mt-2'>Proceed to Pay<img width='40' src={imageName.getstart}></img></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default ItemList;