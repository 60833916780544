const LIMIT_ARRAY = [
    
    {
        label: "10",
        value: "10"
    }, {
        label: "20",
        value: "20"
    }, {
        label: "30",
        value: "30"
    }, {
        label: "40",
        value: "40"
    }, {
        label: "50",
        value: "50"
    }
];

export { LIMIT_ARRAY };