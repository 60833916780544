export function getUserData(){
    return JSON.parse(localStorage.getItem("userInfo"))
}
var obj = {}

export function setItemAddResponse(data){
    obj = data
    console.log(obj);
}

export function getItemResponse(){
    return obj
}