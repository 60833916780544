import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { forgotPass, logIn } from '../RestAPI/Rest';

function LoginModal({
    isVisible,
    onRequestVisible,
    onCreateNewAccount,
    onLoggedIn
}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [forgotemail, setforgotEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailnotify, setemailnotify] = useState('');
    const [passnotify, setpassnotify] = useState('');
    const [loginForgotCheck, setLoginForgotCheck] = useState(true);
    const [values, setValues] = React.useState({ password: "", showPassword: false });
    const [keepLoginCheck, setKeepLoginCheck] = useState(false);


    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const forgotPwd = () => {
        setLoginForgotCheck(false);
    }

    const goAdminLogin = () => {
        window.open('http://admin.valuepros.com/login')
    }

    // for login 
    const onLogin = () => {
        if (email == '') {
            setemailnotify('Please enter email')
            return false;
        } else {
            setemailnotify('')
        }
        if (password == '') {
            setpassnotify('Please enter password')
            return false;
        } else {
            setpassnotify('')
        }

        var info = { "email": email, "password": password, "rememberMe": keepLoginCheck };
        logIn(info).then(res => {
            if (res.success) {
                toast.success("Successfully Logged In")
                localStorage.setItem("userInfo", JSON.stringify(res));
                onLoggedIn(res.data.user);
                onRequestModal();
                
            } else {
                toast.error(res.message);
            }
        })
    }

    // for forgot password api call
    const onSubmitForgot = () => {
        var info = { "email": forgotemail };
        forgotPass(info).then(res => {
            if (res.success) {
                onRequestModal();
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
        })
    }

    // for set the initial
    const setInitialStateData = () => {
        setEmail("");
        setforgotEmail("");
        setPassword("");
        setKeepLoginCheck(false);
        setemailnotify("");
        setpassnotify("");
    }


    // for closing the modal
    const onRequestModal = () => {
        onRequestVisible(false);
        setInitialStateData();
        setLoginForgotCheck(true);
    }

    // for  create acc
    const openCreateAcc = () => {
        onRequestModal();
        onCreateNewAccount();
    }


    return (
        <Modal show={isVisible} onHide={() => onRequestModal()} centered>
            <Modal.Body>
                {loginForgotCheck ?
                    <div className="container">
                        <div className='verticalAlign'>
                            <h2 className='logotxt1'>
                                <img className='logo' src={imageName.logo} />
                            </h2>
                            <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "600" }}>
                                <label >Your single valuation source for all assets.</label>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <span><b>Email:</b></span>
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder=""
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{emailnotify}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 mb-3 passinp'>
                                    <span><b>Password:</b></span>
                                    <input
                                        className="form-control"
                                        type={values.showPassword ? "text" : "password"}
                                        placeholder=""
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="eyecss1">
                                        <span onClick={handleClickShowPassword} className={values.showPassword ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw field-icon toggle-password fa-eye-slash"}></span>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{passnotify}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            value={keepLoginCheck}
                                            onChange={(e) => setKeepLoginCheck(!keepLoginCheck)}
                                            label="Keep me logged in"
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-md-12 text-center' style={{ marginTop: '25px', paddingBottom: '30px' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className={email != '' && password != '' ? 'col-md-12 btnLogin1' : 'col-md-12 btnLogin'} onClick={() => onLogin()}>
                                                <span>Login</span>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='col-md-12 btnCreateAcc' onClick={() => openCreateAcc()}>
                                                <span>Create Account</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' onClick={() => forgotPwd()}>
                                    <h5 style={{ textAlign: 'center', fontSize: '15px', cursor: 'pointer' }}>Forgot Your Password ?</h5>
                                </div>
                                <div className='col-md-12 text-center' style={{ marginTop: '20px', paddingBottom: '30px' }} onClick={() => goAdminLogin()}>
                                    <Button className='btn btnCreateAcc2' type='button'>Administrator / Appraiser Login</Button>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="container">
                        <div className='verticalAlign'>
                            <h2 className='logotxt1'>
                                <img className='logo' src={imageName.logo1} />
                            </h2>
                            <div style={{ textAlign: "center", fontWeight: "600", fontSize: '24px' }}>
                                <label>Password Reset</label>
                            </div>
                            <div className='mb-2' style={{ textAlign: "center", fontWeight: "600", fontSize: '18px' }}>
                                <label>To reset your password, please enter your email below:</label>
                            </div>
                            <div>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder="name@example.com"
                                            value={forgotemail}
                                            onChange={(e) => setforgotEmail(e.target.value)}
                                        />
                                    </Form.Group>

                                    <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                        <Button className='btn btnCreateAcc' type='button' onClick={() => onSubmitForgot()}>Send Email</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}


LoginModal.defaultProps = {
    isVisible: false,
    onRequestVisible: () => { },
    onCreateNewAccount: () => { },
    onLoggedIn: () => { }
};

LoginModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onRequestVisible: PropTypes.func,
    onCreateNewAccount: PropTypes.func,
    onLoggedIn: PropTypes.func
};


export default LoginModal;