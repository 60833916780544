import React,{useEffect} from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import Form from 'react-bootstrap/Form';

function Appraisal() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            let path = `/`
            navigate(path)

        } 
    })

    const checkout = () => {
        let path = `/payment`
        navigate(path)
    }

    return (
        <div className='body'>
            <div className='container'>
                <div className="card mt-2">
                    <div className='row d-flex justify-content-center'>
                        <Header />
                    </div>

                    <div className='row d-flex justify-content-center mt-2'>
                        <div className="col-md-11 menu-card2">
                            <div className='col-md-12'>
                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Please select your desired Appraisal service.</h5>
                                <select className="form-control">
                                    <option>Please select one ...</option>
                                    <option value="75">Valuation Certificate - $75 per item</option>
                                    <option value="125">Appraisal Report - $125 per item</option>               
                                </select>
                            </div>
                            <div className='col-md-12'>
                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Please select a single reason for this appraisal.</h5>
                                <select className="form-control">
                                    <option>Please select one ...</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className='col-md-12'>
                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Please provide the clients full name and address.</h5>
                            </div>
                            <div className='col-md-12'>
                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Full Name (individual or entity)</h5>
                                <input className='form-control' type="text" />
                            </div>
                            <div className='col-md-12'>
                                <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Address(required for USPAP Certification - report is emailed)</h5>
                                <input className='form-control' type="text" />
                            </div>
                            <div className='col-md-12 d-flex justify-content-center'>
                                <div className='checkOut' style={{cursor: "pointer"}} onClick={checkout}>
                                    <h5 className='mt-2'>Checkout&nbsp;&nbsp;<img width="40" src={imageName.getstart}></img></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Appraisal;