import dollarsign from '../image/dollar-sign.png'
import coins from '../image/coins.png'
import check from '../image/check.png'
import capImg from '../image/capImg.jpg'
import fastforward from '../image/fast-forward.png'
import help from '../image/help.png'
import powerbtn from '../image/power-button.png'
import searchhouse from '../image/search-house.png'
import logo from '../image/vp-cropped.png'
import logo1 from '../image/vp1.png'
import logo2 from '../image/vp2.png'
import logout from '../image/logout.png'
import profile from '../image/profile.png'
import paypal from '../image/paypal.svg'
import card from '../image/card.png'
import edit from '../image/edit.png'
import pencil from '../image/pencil.png'
import valuation from '../image/valuation.png'
import appraisal from '../image/appraisal.png'
import onappraisal from '../image/onappraisal.png'
import getstart from '../image/getstart.png'
import additem from '../image/additem.png'
import notification from '../image/notification.png'
import deleteicon from '../image/deleteicon.png'
import camera from '../image/camera.png'
import login from '../image/login.png'
import house from '../image/house.png'
import search from '../image/search.png'
import eye from '../image/eye.png'
import hidden from '../image/hidden.png'
import bg from '../image/bg.jpg'
import cloud from '../image/cloudUpload.png'

export const imageName = Object.freeze({
    dollarsign:dollarsign,
    coins:coins,
    check:check,
    capImg:capImg,
    fastforward:fastforward,
    help:help,
    powerbtn:powerbtn,
    searchhouse:searchhouse,
    logo:logo,
    logo1:logo1,
    logo2:logo2,
    logout:logout,
    profile: profile,
    paypal:paypal,
    card:card,
    edit:edit,
    pencil:pencil,
    valuation:valuation,
    appraisal:appraisal,
    getstart:getstart,
    onappraisal:onappraisal,
    additem:additem,
    notification:notification,
    deleteicon:deleteicon,
    camera:camera,
    login:login,
    house:house,
    search:search,
    eye:eye,
    hidden:hidden,
    bg:bg,
    cloud:cloud
})