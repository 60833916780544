import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Login from './Login'
import Header from './Header'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function ProceedPay() {
    const navigate = useNavigate();
    const [orderid, setorderid] = useState('')
    const [examid, setexamid] = useState('')

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('payInfo')) && JSON.parse(localStorage.getItem('orderId'))) {
            setorderid(JSON.parse(localStorage.getItem('orderId')))
            setexamid(JSON.parse(localStorage.getItem('payInfo')))
        } else {
            let path = `/`
            navigate(path)
        }

    }, [])

    const checkOut = () => {
        if (JSON.parse(localStorage.getItem('valueprosData')).certificateType == 7002) {
            let path = `/valuation-dashboard`
            navigate(path)
            localStorage.removeItem('payInfo')
            localStorage.removeItem('orderId')
            localStorage.removeItem('valueprosData')
        }
        if (JSON.parse(localStorage.getItem('valueprosData')).certificateType == 7001) {
            let path = `/appraisal-dashboard`
            navigate(path)
            localStorage.removeItem('payInfo')
            localStorage.removeItem('orderId')
            localStorage.removeItem('valueprosData')
        }
        // localStorage.removeItem('payInfo')
        // localStorage.removeItem('orderId')
        // let path = `/billing`
        // navigate(path)
    }


    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">
                                <div className="row mt-5" style={{ padding: '50px' }}>
                                    {/* <div className="col-md-12 d-flex justify-content-center">
                                        <h3>Thank you for your payment.</h3>
                                    </div>
                                    <div className="col-md-12 mt-3 d-flex justify-content-center">
                                        <h5>Your paypal transaction id is :&nbsp;&nbsp;{orderid.paypalOrderId}</h5>
                                    </div>
                                    <div className="col-md-12 mt-3 d-flex justify-content-center">
                                        <h5>Your examination id is :&nbsp;&nbsp;{examid.examinationNo}</h5>
                                    </div> */}
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <h3>Thank you for your business.</h3>
                                    </div>
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <h3>Our valuation experts will start working on your order right away and email your report within 3-5 business days!</h3>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12 d-flex justify-content-center">
                                        {/* onClick={checkOut} */}
                                        <div className='mydashboard' style={{ cursor: "pointer" }} onClick={() => checkOut()}>
                                            <h5 className='mt-2'>My Dashboard&nbsp;&nbsp;
                                                <img width="40" src={imageName.getstart}></img></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default ProceedPay;