import React, { useState, useEffect, boolean } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { addHelp, getHelpFAQ } from '../RestAPI/Rest';

export default function Help() {
    const navigate = useNavigate();
    const parse = require('html-react-parser');
    const [onlineData, setonlineData] = useState([])
    const [onsiteData, setonsiteData] = useState([])

    const [help, setHelp] = useState("")
    const [msg, setMsg] = useState("")
    const [contact, setContact] = useState("")

    const [helpNotify, sethelpNotify] = useState('');
    const [msgnotify, setmsgnotify] = useState('');
    const [contactnotify, setcontactnotify] = useState('');


    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in 
        // the input string. Replacing the identified 
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const submitHelp = () => {
        if (help == '') {
            sethelpNotify('Select help option')
            return false
        } else {
            sethelpNotify('')
        }
        if (msg == '') {
            setmsgnotify('Enter messgae')
            return false
        } else {
            setmsgnotify('')
        }
        if (contact == '') {
            setcontactnotify('Select contact option')
            return false
        } else {
            setcontactnotify('')
        }

        let data = {
            "help_topic": help,
            "message": msg,
            "prefer_contact_method": contact
        }

        console.log(data)
        addHelp(data).then(res => {
            if (res.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (res.success) {
                setHelp('')
                setMsg('')
                setContact('')
                sethelpNotify('')
                setmsgnotify('')
                setcontactnotify('')
                setTimeout(() => {
                    toast.success("Inquiry sent successfully.");
                }, 1000);
            } else {
                toast.error("Help request did not send successfully.");
            }
        })
    }

    useEffect(() => {
        getHelpFAQ().then(res => {
            if (res.success) {
                setonlineData(res.data.onlineData)
                setonsiteData(res.data.onSiteData)
                console.log(res.data.onlineData)
            }
            if (res.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
        })
    }, [])

    const returnHTMLData =(html)=>{
        return (parse(html));
    }

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">
                                {
                                    JSON.parse(localStorage.getItem("userInfo")) ?
                                        <div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-4 d-flex justify-content-start">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Frequently Asked Questions:</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-5 d-flex justify-content-start">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Online Appraisal Service FAQ</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center mt-3'>
                                                {
                                                    onlineData.map((item, i) =>
                                                        // <div>{item.question}</div>
                                                        <div key={i} className="col-md-11 d-flex justify-content-start ">
                                                            <Accordion style={{ border: '1px solid #000000', width: "100%" }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography align='left'>{item.question}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography align='left'>
                                                                    { returnHTMLData(item.answer) }
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-5 d-flex justify-content-start">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Onsite Appraisal Service FAQ</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center mt-3 mb-3'>
                                                {
                                                    onsiteData.map((item, i) =>
                                                        // <div>{item.question}</div>
                                                        <div key={i} className="col-md-11 d-flex justify-content-start ">
                                                            <Accordion style={{ border: '1px solid #000000', width: "100%" }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography align='left'>{item.question}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography align='left'>
                                                    
                                                                        { returnHTMLData(item.answer) }
                                                                        
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-4 d-flex justify-content-center">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Need more help? Contact Tech Support at</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-3 d-flex justify-content-center">
                                                    <span style={{ fontSize: '18px', fontWeight: '500' }}>
                                                        (888) 353-7152 Monday – Friday 8 a.m. to 5 p.m. EST.</span>
                                                </div>
                                                <div className="col-md-11 mt-3 d-flex justify-content-center">
                                                    <h3 style={{ fontSize: '18px', fontWeight: '500' }}><a style={{ color: '#61dafb' }} href="mailto:support@valuepros.com">support@valuepros.com</a></h3>
                                                </div>
                                            </div>

                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-3 d-flex justify-content-center">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Send us a message:</span>
                                                </div>
                                                <div className="col-md-11 mt-5 d-flex justify-content-start">
                                                    <span style={{ fontSize: '18px', fontWeight: '500' }}>
                                                        *I need help with...</span>
                                                </div>
                                                <div className="col-md-11 mt-1 d-flex justify-content-start">
                                                    <select className="form-control" value={help} onChange={(e) => { setHelp(e.target.value) }}>
                                                        <option value="">Select from the following ...</option>
                                                        <option value="1">Submit Online Appraisal</option>
                                                        <option value="2">Schedule Onsite Appraisal</option>
                                                        <option value="3">Setup a new location</option>
                                                        <option value="4">Find my appraisal reports</option>
                                                    </select>

                                                </div>
                                                <div className="col-md-11 mt-1 d-flex justify-content-start">
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{helpNotify}</div>
                                                </div>

                                                <div className="col-md-11 mt-3 d-flex justify-content-start">
                                                    <span style={{ fontSize: '18px', fontWeight: '500' }}>
                                                        *Message or question</span>
                                                </div>
                                                <div className="col-md-11 mt-1 d-flex justify-content-start">
                                                    <textarea className="form-control" onChange={(e) => { setMsg(e.target.value) }} value={msg}>
                                                    </textarea>
                                                </div>
                                                <div className="col-md-11 mt-1 d-flex justify-content-start">
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{msgnotify}</div>
                                                </div>

                                                <div className="col-md-11 mt-3 d-flex justify-content-start">
                                                    <span style={{ fontSize: '18px', fontWeight: '500' }}>
                                                        *How do you prefer to be contacted?</span>
                                                </div>
                                                <div className="col-md-11 mt-1 d-flex justify-content-start">
                                                    <select className="form-control" onChange={(e) => { setContact(e.target.value) }} value={contact}>
                                                        <option value="">Select from the following ...</option>
                                                        <option value="1">Phone</option>
                                                        <option value="2">Email</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-11 mt-1 d-flex justify-content-start">
                                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{contactnotify}</div>
                                                </div>
                                            </div>

                                            <div className='row d-flex justify-content-center'>
                                                <div className='col-md-12 d-flex justify-content-center'>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {/* <div className='checkOut' style={{ cursor: "pointer" }} onClick={submitHelp}>
                                                                <h5 className='mt-2'>Submit&nbsp;&nbsp;
                                                                    <img width="40" src={imageName.getstart}></img></h5>
                                                            </div> */}

                                                            <div className='helpsub' style={{ cursor: 'pointer' }} onClick={submitHelp}>
                                                                <div className='row'>
                                                                    <div className="col-md-12">
                                                                        <h5 className='mt-2'>Submit<img width='40' src={imageName.getstart}></img></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-4 d-flex justify-content-start">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Frequently Asked Questions:</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-5 d-flex justify-content-start">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Online Appraisal Service FAQ</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center mt-3'>
                                                {
                                                    onlineData.map((item, i) =>
                                                        // <div>{item.question}</div>
                                                        <div key={i} className="col-md-11 d-flex justify-content-start ">
                                                            <Accordion style={{ border: '1px solid #000000', width: "100%" }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography align='left'>{item.question}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography align='left'>
                                                                        {
                                                                            returnHTMLData(item.answer)
                                                                        }
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-5 d-flex justify-content-start">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Onsite Appraisal Service FAQ</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center mt-3 mb-3'>
                                                {
                                                    onsiteData.map((item, i) =>
                                                        // <div>{item.question}</div>
                                                        <div key={i} className="col-md-11 d-flex justify-content-start ">
                                                            <Accordion style={{ border: '1px solid #000000', width: "100%" }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography align='left'>{item.question}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography align='left'>
                                                                        {
                                                                            returnHTMLData(item.answer)
                                                                        }
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-4 d-flex justify-content-center">
                                                    <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                        Need more help? Contact Tech Support at</span>
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className="col-md-11 mt-3 d-flex justify-content-center">
                                                    <span style={{ fontSize: '18px', fontWeight: '500' }}>
                                                        (888) 353-7152 Monday – Friday 8 a.m. to 5 p.m. EST.</span>
                                                </div>
                                                <div className="col-md-11 mt-3 mb-5 d-flex justify-content-center">
                                                    <h3 style={{ fontSize: '18px', fontWeight: '500' }}><a style={{ color: '#61dafb' }} href="mailto:support@valuepros.com">support@valuepros.com</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}