import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import Header from './Header';
import { getItemResponse, getUserData } from '../common/Common';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useParams } from 'react-router-dom';
import { finalPayment, getExaminationRes, promoCode, delPromoCode } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { MdEditNote, MdDelete } from "react-icons/md";

export default function Payment() {
    const navigate = useNavigate();
    let paydetails = JSON.parse(localStorage.getItem("payInfo"));
    let qty = JSON.parse(localStorage.getItem("payInfo")).itemCount;
    let price = JSON.parse(localStorage.getItem("payInfo")).price;
    let total = JSON.parse(localStorage.getItem("payInfo")).total;
    let examinationId = JSON.parse(localStorage.getItem("payInfo")).id;
    let userId = getUserData().data.user.id;
    let clientId = "ATvW1lrbt6uCwZ10sIzK7dijE6mZ2HMBKK1h1iFG4w3h3HpIf51osW7DD5x8Of1MmzJAUxl_b-usLr70"

    const [promo, setPromo] = useState('')
    const [promoId, setPromoId] = useState('')
    const [proId, setProId] = useState('')
    const [promoCheck, setPromoCheck] = useState(false)
    const [totalPrice, setTotalPrice] = useState('')
    const [subtotalPrice, setSubtotalPrice] = useState('')
    const [financialStatus, setFinancialStatus] = useState('')
    const [totalDiscounts, setTotalDiscounts] = useState('')
    const [value, setValue] = useState([]);
    const [loader, setLoader] = useState(false);
    var description = ''
    var custom_id = ''
    // var examinationId = ''
    var arr = []

    function applyPromo() {
        var data = {
            'code': promo
        }
        console.log('---Promo', data)
        promoCode(data).then(res => {
            if (res.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (res.success) {
                toast.success(res.message);
                setPromoId(res.data.promo.code);
                setProId(res.data.promo.id);
                setTotalPrice(res.data.subtotalPrice);
                setSubtotalPrice(res.data.subtotalPrice);
                setFinancialStatus(res.data.financialStatus);
                setTotalDiscounts(res.data.totalDiscounts);
                setPromoCheck(true);
                var data = {
                    "examinationNo": paydetails.examinationNo,
                    "id": paydetails.id,
                    "itemCount": paydetails.itemCount,
                    "total": parseInt(res.data.subtotalPrice),
                    "price": paydetails.price
                }
                localStorage.setItem('payInfo', JSON.stringify(data))
            } else {
                toast.error(res.message);
            }
        })
    }

    function deletePromo() {
        var data = {
            'promoCode': proId
        }
        setLoader(true);
        // console.log('---Promo', data)
        delPromoCode(data).then(res => {
            if (res.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (res.success) {
                toast.success(res.message);
                setPromo('');
                // setPromoId(res.data.promo.code);
                // setProId(res.data.promo.id);
                setTotalPrice(res.data.subtotalPrice);
                setSubtotalPrice(res.data.subtotalPrice);
                setFinancialStatus(res.data.financialStatus);
                setTotalDiscounts(res.data.totalDiscounts);
                setLoader(false);
                setPromoCheck(false);
                var data = {
                    "examinationNo": paydetails.examinationNo,
                    "id": paydetails.id,
                    "itemCount": paydetails.itemCount,
                    "total": parseInt(res.data.subtotalPrice),
                    "price": paydetails.price
                }
                localStorage.setItem('payInfo', JSON.stringify(data))
            } else {
                toast.error(res.message);
            }
        })
    }

    useEffect(() => {
        var data1 = {
            "examinationId": examinationId,
            "userId": userId
        }
        console.log(data1)
        getExaminationRes(data1).then(res => {
            if (res.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (res.message == "Success") {
                setValue(res.data)
                console.log(value)
            } else {
                console.log("getExaminationRes---------------> Data fetch not successful");
            }
        })
    }, [])

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">
                                <div className="row">
                                    <div className="login-wrapper d-flex justify-content-center align-items-center min-vh-100">
                                        <div className='verticalAlign'>
                                            <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "500", fontSize: '32px' }}>
                                                <label>Checkout</label>
                                                {/* <div style={{ justifyContent: "right", float: "right", cursor: "pointer" }}>
                                                    <MdEditNote size={40} onClick={()=>navigate("/item-list")}/>
                                                </div> */}
                                            </div>

                                            <div className='payOutline'>
                                                {/* <div className='row'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Items</th>
                                                                <th scope="col">Price</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                value.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{item.itemName}</td>
                                                                        <td>{"$" + price.toFixed(2)}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Promo code"
                                                            value={promo}
                                                            onChange={(e) => setPromo(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <button className='btn btn-primary marginTop' type="button" onClick={applyPromo}>Apply</button>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='hr'></div>
                                                </div>
                                                {
                                                    loader ? null :
                                                        promoCheck ?
                                                            <div>
                                                                <div className='row d-flex justify-content-end'>
                                                                    <div className='col-md-8 d-flex justify-content-start'>
                                                                        <div className='row'>
                                                                            <div className='col-md-12 d-flex justify-content-start'>
                                                                                <span style={{ fontWeight: '500' }}>Promo Code:&nbsp;&nbsp;{promoId}</span>
                                                                            </div>
                                                                            <div className='col-md-12 d-flex justify-content-start'>
                                                                                <span style={{ fontWeight: '500' }}>Discount Value:&nbsp;&nbsp;{'$' + parseInt(totalDiscounts).toFixed(2)}</span>
                                                                            </div>
                                                                            <div className='col-md-12 d-flex justify-content-start'>
                                                                                <span style={{ fontWeight: '500' }}>Total Payment: &nbsp;&nbsp;{'$' + parseInt(subtotalPrice).toFixed(2)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4 d-flex justify-content-center'>
                                                                        <MdDelete size={25} onClick={() => deletePromo()} style={{ cursor: 'pointer', color: '#000000' }} />
                                                                    </div>
                                                                </div>

                                                                <div className='row d-flex justify-content-end'>
                                                                    <div className='col-md-12 d-flex justify-content-end item'>
                                                                        <span style={{ fontSize: '18px', fontWeight: '600' }}>Total: &nbsp;&nbsp;{`$` + parseInt(subtotalPrice).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <div className='row d-flex justify-content-end'>
                                                                <div className='col-md-12 d-flex justify-content-end item'>
                                                                    <span style={{ fontSize: '18px', fontWeight: '600' }}>Total: &nbsp;&nbsp;{`$` + parseInt(total).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                }
                                            </div>


                                            <div className='row d-flex justify-content-center'>
                                                {/* <div className="col-md-11 mt-5 paypal" style={{ cursor: 'pointer' }}>
                                                    <img src={imageName.paypal} width='70'></img>
                                                </div> */}
                                                <div className="col-md-11 mt-5">
                                                    <PayPalScriptProvider options={{ "client-id": clientId }}>
                                                        <PayPalButtons

                                                            createOrder={(data, actions) => {
                                                                return actions.order.create({
                                                                    "purchase_units": [
                                                                        {
                                                                            "description": description,
                                                                            "amount": {
                                                                                "currency_code": "USD",
                                                                                "value": JSON.parse(localStorage.getItem("payInfo")).total
                                                                            },
                                                                            "custom_id": parseInt(examinationId)
                                                                        }
                                                                    ],
                                                                    "intent": "CAPTURE",
                                                                    "application_context": {}
                                                                });
                                                            }}
                                                            onApprove={(data, actions) => {
                                                                return actions.order.capture().then((details) => {
                                                                    // console.log("------------------------")
                                                                    // console.log(details)

                                                                    const name = details.payer.name.given_name;
                                                                    var paypalOrderId = details.id
                                                                    var customId = parseInt(custom_id)
                                                                    // parseInt(details.purchase_units[0].custom_id)

                                                                    var data1 = {
                                                                        "examinationId": parseInt(examinationId),
                                                                        "paypalOrderId": paypalOrderId
                                                                    }
                                                                    // console.log("---------------------------")
                                                                    console.log(data1)

                                                                    finalPayment(data1).then(res => {

                                                                        if (res.success) {
                                                                            toast.success("Payment successful.");
                                                                            // localStorage.removeItem("valueprosData");

                                                                            setTimeout(() => {
                                                                                localStorage.setItem('orderId', JSON.stringify(data1))
                                                                                toast.success(`Transaction completed by ${name}`);
                                                                                let path = `/proceed-pay`
                                                                                navigate(path)
                                                                            }, 2000);

                                                                        } else {
                                                                            toast.error("Payment unsuccessfull.");
                                                                        }

                                                                        if (res.code == 401) {
                                                                            localStorage.clear()
                                                                            let path = `/`
                                                                            navigate(path)
                                                                            setTimeout(() => {
                                                                                toast.error("Sorry, Session Expired");
                                                                            }, 1000);
                                                                        }
                                                                    })

                                                                })
                                                            }}

                                                        />
                                                    </PayPalScriptProvider>
                                                </div>
                                                {/* <div className="col-md-11 mt-3 paycard" style={{ cursor: 'pointer' }}>
                                                    <span style={{ color: "#ffffff" }}><i className="fab fa-cc-paypal"></i>&nbsp;&nbsp;Debit or Credit Card</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );

}