import React, { useEffect, useState } from 'react';
import '../../App.css';
import { json, useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Login from './Login'
import Header from './Header'
import { LoginModal, SignupModal } from '../shared';
import { getUserNotif } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function Dashboard() {
    const navigate = useNavigate();
    const [visibleLoginModal, setVisibleLoginModal] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [visibleCreateAccountModal, setVisibleCreateAccountModal] = useState(false);


    useEffect(() => {
        getUserData();
    }, [])

    // for getting user data from local storage
    const getUserData = () => {
        if (JSON.parse(localStorage.getItem("userInfo"))) {
            getNotif();
        } 
    }

    // get notification count
    const getNotif = () => {
        var data = { 'pageNo': 1, 'limit': 10 };
        getUserNotif(data).then(result => {
            var notiCount = 0;
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (result.success) {
                for (let i = 0; i < result.data.data.length; i++) {
                    if (result.data.data[i].isSeen == false) {
                        notiCount++;
                    }
                }
            }
            setNotificationCount(notiCount);
        })
    }

    const goItem = () => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            toast.warning("Please LOGIN to Get Started.")
            let path = `/`
            navigate(path)
        } else {
            let path = `/item`
            navigate(path)
        }
    }

    const goToContact = () => {
        let path = `/learn-more`
        navigate(path)
    }

    // for set the login data
    const onSetLoginData = async (value) => {
        window.location.reload();
    }

    const isGetStart = () => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            setVisibleLoginModal(true);
        } else {
            let path = `/item`
            navigate(path)
        }
    }

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center'>
                            <div className="col-md-12 menu-card2">
                                <div className='col-md-12'>
                                    <div className="row">
                                        <div className="col-md-8 mt-3">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <h4 className='text-left'>Welcome to ValuePros</h4>
                                                    <h5 className='text-left h5desc'>ValuePros is the national leader in property appraisal and valuation services.</h5>
                                                </div>
                                            </div>
                                            {/* <div className='row'> */}
                                            {/* <div className='col-md-12'> */}
                                            <h4 className='text-left whyvph4 mt-3'>Why ValuePros</h4>
                                            {/* </div> */}
                                            {/* <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Independent accredited ASA, ISA, AAA and GIA appraisal experts.</h5>
                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Over 1.28 Million appraisal delivered.</h5>
                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;255,000 customers served.</h5> */}
                                            {/* </div > */}

                                            <div className='row'>
                                                {/* <ul >
                                                    <li className='b1'>
                                                    <h5 className='text-left h5desc'>Independent accredited ASA, ISA, AAA and GIA appraisal experts.</h5>
                                                    </li>
                                                    <li className='b1'><h5 className='text-left h5desc'>Over 1.28 Million appraisal delivered.</h5></li>
                                                    <li className='b1'><h5 className='text-left h5desc'>255,000 customers served.</h5></li>
                                                </ul> */}
                                                <ul className='ulinfo'>
                                                    <li className='liinfo'>Independent accredited ASA, ISA, AAA and GIA appraisal experts.</li>
                                                    <li className='liinfo'>Over 1.28 Million appraisals delivered.</li>
                                                    <li className='liinfo'>255,000 customers served.</li>
                                                </ul>
                                            </div>

                                            <div className='mb-4'>
                                                <h5 className='text-left h5desc mt-3'>Choose your appraisal option and get started! It's that easy!</h5>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-4">
                                            <img className='capImg rounded mx-auto d-block' src={imageName.bg}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center mt-2 valappmar'>
                            <div className="col-md-12">
                                <div className="row d-flex justify-content-center">
                                    <div className='col-md-6 menu-card3'>
                                        <div className='col-md-12'>
                                            <div className="row">
                                                <div className='col-md-12 mb-3'>
                                                    <div className='row mb-1'>
                                                        <div className='col-md-12 d-flex justify-content-start'>
                                                            <img className='valdollar' src={imageName.valuation}></img>&nbsp;&nbsp;<h4 className='text-left valcer'>Valuation Certificate</h4>
                                                        </div>
                                                    </div>
                                                    {/* <h4 className='text-left valcer'><img className='valdollar' src={imageName.valuation}></img>&nbsp;&nbsp;Valuation Certificate</h4> */}
                                                    <h5 className='text-left h5desc'>Valuation Certificates for:</h5>

                                                    {/* <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Buying & Selling Decisions</h5>
                                                    <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Curiosity</h5>
                                                    <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Entertainment</h5> */}
                                                    <div className='row'>

                                                        <ul className='ulinfo'>
                                                            <li className='liinfo'>Buying & Selling Decisions</li>
                                                            <li className='liinfo'>Curiosity</li>
                                                            <li className='liinfo'>Entertainment</li>
                                                        </ul>
                                                    </div>

                                                    <h5 className='text-left'>$75 per item</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-1'></div> */}
                                    <div className='col-md-6 menu-card4'>
                                        <div className='col-md-12'>
                                            <div className="row">
                                                <div className='col-md-12 mb-3'>
                                                    <div className='row mb-1'>
                                                        <div className='col-md-12 d-flex justify-content-start'>
                                                            <img className='appcoins' src={imageName.appraisal}></img>&nbsp;&nbsp;<h4 className='text-left appcer'>Appraisal Report</h4>
                                                        </div>
                                                    </div>
                                                    {/* <h4 className='text-left appcer'><img className='appcoins' src={imageName.appraisal}></img>&nbsp;&nbsp;Appraisal Report</h4> */}
                                                    <h5 className='text-left h5desc'>USPAP Compliant Appraisal Reports for:</h5>
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            {/* <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Insurance</h5>
                                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Estate Settlement</h5>
                                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Charitable Donation</h5> */}

                                                            <div className='row'>

                                                                <ul className='ulinfo'>
                                                                    <li className='liinfo'>Insurance</li>
                                                                    <li className='liinfo'>Estate Settlement</li>
                                                                    <li className='liinfo'>Charitable Donation</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            {/* <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Marital Dissolution</h5>
                                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Damage Claims</h5>
                                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Financial Planning</h5> */}

                                                            <div className='row'>

                                                                <ul className='ulinfo'>
                                                                    <li className='liinfo'>Marital Dissolution</li>
                                                                    <li className='liinfo'>Damage Claims</li>
                                                                    <li className='liinfo'>Financial Planning</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5 className='text-left'>$125 per item</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <div className="col-md-12 menu-card2">
                                <div className='col-md-12'>
                                    <div className="row">
                                        <div className="col-md-8 mt-3">
                                            <div>
                                                <h4 className='text-left getstarted'>Get started right now!</h4>
                                            </div>
                                            <div className='row'>
                                                {/* <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Find out the value of your assets!</h5>
                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;Your virtual appraisal will be emailed in 3-5 business days!</h5>
                                            <h5 className='text-left h5desc'><img className='check' src={imageName.check}></img>&nbsp;&nbsp;It's that easy!</h5> */}
                                                {/* <ul>
                                                    <li className="b1"><h5 className='text-left h5desc'>Find out the value of your assets!</h5></li>
                                                    <li className="b1"><h5 className='text-left h5desc'>Your virtual appraisal will be emailed in 3-5 business days!</h5></li>
                                                    <li className="b1"><h5 className='text-left h5desc'>It's that easy!</h5></li>
                                                </ul> */}
                                                <ul className='ulinfo'>
                                                    <li className='liinfo'>Find out the value of your assets!</li>
                                                    <li className='liinfo'>Your virtual appraisal will be emailed in 3-5 business days!</li>
                                                    <li className='liinfo'>It's that easy!</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {/* onClick={goItem} */}
                                            <div className='getStart' style={{ cursor: 'pointer' }} onClick={() => isGetStart()}>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <h5 className='mt-2'>Get Started<img width='40' src={imageName.getstart}></img></h5>
                                                    </div>
                                                    {/* <div className="col-md-2">
                                                        <img width='40' src={imageName.getstart}></img>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 mt-5 mb-5 common'>
                        <h5 className='onsiteh5'><img className='searchhouse' src={imageName.onappraisal}></img>&nbsp;&nbsp;Onsite Service</h5>
                        <h5 className='onsiteh5info mt-2'>
                            Have many items, an estate, or property to appraise?<br></br>
                            <div className='lineheight'></div>
                            Our national team of online examiners will travel to your location.
                        </h5>
                        <div className='row'>
                            <div className='col-md-12 d-flex justify-content-center'>
                                <div className='learnMore' onClick={goToContact}>
                                    <h5 className='mt-2'>Learn More<img width='40' src={imageName.getstart}></img></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SignupModal
                    isVisible={visibleCreateAccountModal}
                    onRequestVisible={(value) => setVisibleCreateAccountModal(value)}
                // onLoggedIn={(value) => onSetLoginData(value)}
                />
                <LoginModal
                    isVisible={visibleLoginModal}
                    onRequestVisible={(value) => setVisibleLoginModal(value)}
                    onLoggedIn={(value) => onSetLoginData(value)}
                    onCreateNewAccount={(value) => setVisibleCreateAccountModal(true)}
                />
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );
}

export default Dashboard;