import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import { resetPass } from '../RestAPI/Rest';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function ResetPass() {
    const navigate = useNavigate();
    let { id } = useParams();

    var [pass, setPass] = useState('');
    var [cnfPass, setcnfPass] = useState("");
    var [passNotif, setPassNotif] = useState('');
    var [cnfPassNotif, setCnfPassNotif] = useState('');
    var [chkNewCnfNotif, setChkNewCnfNotif] = useState('');
    var [lenPassNotif, setlenPassNotif] = useState('');
    var [lenCnfPassNotif, setlenCnfPassNotif] = useState('');

    const [passValues, setpassValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [newPassValues, setnewPassValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const handleClickPass = () => {
        setpassValues({ ...passValues, showPassword: !passValues.showPassword });
    };
    const handleClickNewPass = () => {
        setnewPassValues({ ...newPassValues, showPassword: !newPassValues.showPassword });
    };


    const submitThis = () => {
        var regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{6,15}$/;
        if (pass == '') {
            setPassNotif("Enter new password");
            setCnfPassNotif('')
            setlenPassNotif('')
            setlenCnfPassNotif('')
            return false;
        } else {
            if (pass != '') {
                if (!regexPass.test(pass)) {
                    setlenPassNotif("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                    setPassNotif('')
                    return false
                } else {
                    setlenPassNotif('')
                }
            }
            setPassNotif('')
        }
        if (cnfPass == '') {
            setCnfPassNotif("Enter confirm password");
            setPassNotif('')
            setlenPassNotif('')
            setlenCnfPassNotif('')
            return false;
        } else {
            if (cnfPass != '') {
                if (!regexPass.test(cnfPass)) {
                    setlenCnfPassNotif("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                    setCnfPassNotif('')
                    return false
                } else {
                    setlenCnfPassNotif('')
                }
            }
            setCnfPassNotif('')
        }
        if (pass != cnfPass) {
            setChkNewCnfNotif("Password and confirm password did not match");
            setPassNotif('')
            setCnfPassNotif('')
            setlenPassNotif('')
            setlenCnfPassNotif('')
            return false;
        } else {
            setChkNewCnfNotif('')
        }

        var token = id
        var info = { "token": token, "password": pass };

        resetPass(info).then(res => {
            if (res.success) {
                setPass("");
                setcnfPass("");
                setpassValues({ showPassword: false });
                setnewPassValues({ showPassword: false });
                toast.success(res.message ? res.message : "");
                setTimeout(() => {
                    let path = `/`
                    navigate(path)
                }, 5000);
            } else {
                toast.error(res.message ? res.message : "");
            }

        })
    }

    const toHome = () => {
        let path = `/`
        navigate(path)
    }

    return (
        <>
            <div className="container vh-100">
                <div className="row">
                    <div className="login-wrapper d-flex justify-content-center align-items-center min-vh-100">
                        <div className='verticalAlign'>
                            <h2 className='logotxt1'>
                                <img className='reslogo' src={imageName.logo2} />
                            </h2>
                            <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "500", fontSize: '18px' }}>
                                <label>RESET YOUR PASSWORD</label>
                            </div>

                            <div>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <input
                                            className="form-control"
                                            type={passValues.showPassword ? "text" : "password"}
                                            placeholder="*Password"
                                            value={pass}
                                            onChange={(e) => setPass(e.target.value)}
                                        />
                                        <div className="eyecss2">
                                            {

                                                passValues.showPassword ?
                                                    <span onClick={handleClickPass} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickPass} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                            }
                                        </div>

                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{passNotif}</div>
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{lenPassNotif}</div>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <input
                                            className="form-control"
                                            type={newPassValues.showPassword ? "text" : "password"}
                                            placeholder="*Confirm Password"
                                            value={cnfPass}
                                            onChange={(e) => setcnfPass(e.target.value)}
                                        />
                                        <div className="eyecss2">
                                            {

                                                newPassValues.showPassword ?
                                                    <span onClick={handleClickNewPass} className="fa fa-fw field-icon toggle-password fa-eye-slash"></span> : <span onClick={handleClickNewPass} className="fa fa-fw fa-eye field-icon toggle-password"></span>

                                            }
                                        </div>

                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{cnfPassNotif}</div>
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{lenCnfPassNotif}</div>
                                    </Form.Group>

                                    <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{chkNewCnfNotif}</div>

                                    <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                        <Button className='btn btnCreateAcc' type='button' onClick={submitThis}>Reset Password</Button>
                                    </div>

                                    <div onClick={toHome}>
                                        <h5 style={{ textAlign: 'center', fontSize: '15px', cursor: 'pointer' }}>Go to Homepage</h5>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );
}