import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import Form from 'react-bootstrap/Form';
import { getCatOpt, uploadFile, getReason, addExamination } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function VerifyEmail() {
    const navigate = useNavigate();
    const [verifyEmail, setverifyEmail] = useState('');

    const verify = () => {

    }

    // useEffect(() => {
    //     if (!JSON.parse(localStorage.getItem("userInfo"))) {
    //         let path = `/`
    //         navigate(path)
    //     }
    // }, [])

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        {/* <div className='row d-flex justify-content-center'>
                            <Header />
                        </div> */}

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-11 menu-card2">
                                <div className='row'>
                                    {/* <div className='col-md-12'>
                                        <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Verify Email with Token</h5>
                                        <input className='form-control' placeholder='Enter token' type="text" onChange={(e) => { setverifyEmail(e.target.value) }} />
                                    </div>

                                    <div className="col-md-6">
                                        <div className='verify' style={{ cursor: "pointer" }} onClick={verify}>
                                            <h5 className='mt-2'>Verify&nbsp;&nbsp;
                                                <img width="40" src={imageName.getstart}></img></h5>
                                        </div>
                                    </div> */}
                                    <div className='col-md-12'>
                                        <img className='logo1' src={imageName.logo} />
                                    </div>

                                    <div className='col-md-12'>
                                        <h2>Your account has been created successfully.</h2>
                                    </div>

                                    <div className='col-md-12 mt-3'>
                                        <h3 style={{fontSize: '20px'}}>We just sent a Welcome email to your email address.</h3><br></br> 
                                        <h3 style={{fontSize: '20px'}}>Please click on the link in the Welcome email<br></br> to verify your account.</h3>
                                    </div>

                                    <div className='col-md-12 mt-2 mb-3'>
                                        <h3 style={{fontSize: '15px'}}>If you don't receive the email,<br></br> please check your spam folder.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default VerifyEmail;