import React, { useState } from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import { userQuery } from '../RestAPI/Rest';
import Login from './Login'
import Header from './Header'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function Help() {
    const navigate = useNavigate();

    var [fname, setfname] = useState("");
    var [lname, setlname] = useState("");
    var [email, setemail] = useState("");
    var [city, setcity] = useState("");
    var [region, setregion] = useState("");
    var [reason, setreason] = useState("");
    var [item, setitem] = useState("");
    var [more, setmore] = useState("");

    const [fnamenotify, setfnamenotify] = useState('');
    const [lnamenotify, setlnamenotify] = useState('');
    const [emailnotify, setemailnotify] = useState('');
    const [citynotify, setcitynotify] = useState('');
    const [regionnotify, setregionnotify] = useState('');
    const [reasonnotify, setreasonnotify] = useState('');
    const [itemnotify, setitemnotify] = useState('');
    const [morenotify, setmorenotify] = useState('');
    const [morecountnotify, setmorecountnotify] = useState('');

    function mysqlEscape(stringToEscape){
        if(stringToEscape == '') {
            return stringToEscape;
        }
    
        return stringToEscape
            .replace(/\\/g, "\\\\")
            .replace(/\'/g, "\\\'")
            .replace(/\"/g, "\\\"")
            .replace(/\n/g, "\\\n")
            .replace(/\r/g, "\\\r")
            .replace(/\x00/g, "\\\x00")
            .replace(/\x1a/g, "\\\x1a");
    }

    const onSubmit = () => {

        if (fname == '') {
            setfnamenotify('Please enter first name')
            return false
        } else {
            setfnamenotify('')
        }
        if (lname == '') {
            setlnamenotify('Please enter last name')
            return false
        } else {
            setlnamenotify('')
        }
        if (email == '') {
            setemailnotify('Please enter email')
            return false
        } else {
            setemailnotify('')
        }
        if (city == '') {
            setcitynotify('Please enter city')
            return false
        } else {
            setcitynotify('')
        }
        if (region == '') {
            setregionnotify('Please enter region')
            return false
        } else {
            setregionnotify('')
        }
        if (reason == '') {
            setreasonnotify('Please select reason')
            return false
        } else {
            setreasonnotify('')
        }
        if (item == '') {
            setitemnotify('Please select item')
            return false
        } else {
            setitemnotify('')
        }
        if (more == '') {
            setmorecountnotify('')
            setmorenotify('Please enter more details')
            return false
        } else {
            setmorenotify('')
        }
        if (more.length > 250) {
            setmorenotify('')
            setmorecountnotify('Message should be less than 250 characters')
            return false
        } else {
            setmorecountnotify('')
        }

        var extra = mysqlEscape(more)

        var data = {
            "firstName": fname,
            "lastName": lname,
            "email": email,
            "city": city,
            "state": region,
            "reason": reason,
            "items": item,
            "description": extra
        }

        console.log(data)

        userQuery(data).then(res => {
            if (res.success) {
                toast.success("Thank you for your message. It has been sent.");
                setfname('')
                setlname('')
                setemail('')
                setcity('')
                setregion('')
                setreason("")
                setitem("")
                setmore('')
                setTimeout(() => {
                    let path = `/`
                    navigate(path)
                }, 2000);
            } else {
                toast.error("Message not sent");
            }
        })
    }

    return (
        <div className='body'>
            <div className='container'>
                <div className="card mt-2">
                    <div className='d-flex justify-content-center'>
                        <Header />

                    </div>

                    <div className='d-flex justify-content-center mt-2'>
                        <div className="col-md-12 menu-card2">
                            <div className='row'>
                                <div className='col-md-12 mt-5'>
                                    <h5 style={{ fontWeight: '500' }}>Contact us today to discuss your personal property appraisal needs.</h5>
                                </div>
                                <div className='col-md-12 mt-5'>
                                    <h1 style={{ textAlign: "center" }}><a style={{ textAlign: "center", fontSize: "42px", color: "#0082cc!important", textDecoration: "None" }} href="mailto:support@valuepros.com">support@valuepros.com</a></h1>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <h2 style={{ textAlign: "center" }}>Call Toll-Free Nationwide (US):<br></br>
                                        <a href="tel:8883537152" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "None" }}><i className="fa fa-phone-square-alt"></i>&nbsp;&nbsp;(888) 353-7152</a>
                                    </h2>
                                </div>

                                <div className='col-md-12 mt-5'>
                                    <h3 style={{ textAlign: "center", fontWeight: '400' }}>If you have any questions or comments, please send us a message:</h3>
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center'>
                                <div className='col-md-12 mt-4 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*First Name</h5>
                                        <input
                                            className="form-control"
                                            maxLength={128}
                                            type="text"
                                            placeholder=""
                                            value={fname}
                                            onChange={(e) => setfname(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{fnamenotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*Last Name</h5>
                                        <input
                                            className="form-control"
                                            maxLength={128}
                                            type="text"
                                            placeholder=""
                                            value={lname}
                                            onChange={(e) => setlname(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{lnamenotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*Email</h5>
                                        <input
                                            className="form-control"
                                            maxLength={255}
                                            type="text"
                                            placeholder=""
                                            value={email}
                                            onChange={(e) => setemail(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{emailnotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*City</h5>
                                        <input
                                            className="form-control"
                                            maxLength={100}
                                            type="text"
                                            placeholder=""
                                            value={city}
                                            onChange={(e) => setcity(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{citynotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*State/Region</h5>
                                        <input
                                            className="form-control"
                                            maxLength={128}
                                            type="text"
                                            placeholder=""
                                            value={region}
                                            onChange={(e) => setregion(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{regionnotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*What is the reason for an independent appraisal?</h5>
                                        <select className="form-control" aria-required="true" aria-invalid="false" name="reason-appraisal" onChange={(e) => setreason(e.target.value)}>
                                            <option value="">—Please choose an option—</option>
                                            <option value="Insurance Coverage">Insurance Coverage</option>
                                            <option value="Estate Settlement">Estate Settlement</option>
                                            <option value="Equitable Distribution">Equitable Distribution</option>
                                            <option value="Charitable Donation">Charitable Donation</option><option value="Marital Dissolution">Marital Dissolution</option>
                                            <option value="Buy/Sell Value">Buy/Sell Value</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{reasonnotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*Approximately how many items/sets would you like to be appraised?</h5>
                                        <select className="form-control" aria-required="true" aria-invalid="false" name="number-items" onChange={(e) => setitem(e.target.value)}>
                                            <option value="">—Please choose an option—</option>
                                            <option value="1-5 items">1-5 items</option>
                                            <option value="5-10 items">5-10 items</option>
                                            <option value="10-20 items">10-20 items</option>
                                            <option value="20-50 items">20-50 items</option>
                                            <option value="50+ items">50+ items</option>
                                        </select>
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{itemnotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className='col-md-8 mb-3'>
                                        <h5 style={{ textAlign: 'left', fontSize: '18px' }}>*Tell us more about your property and needs. To expedite the process, please be as specific as possible.</h5>
                                        <textarea className="form-control" value={more} maxLength="250" onChange={(e) => setmore(e.target.value)}></textarea>
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{morenotify}</div>
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{morecountnotify}</div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-5 d-flex justify-content-center'>
                                    <div className="col-md-8 mb-2">
                                        {/* <div className='checkOut' style={{ cursor: "pointer" }} onClick={onSubmit}>
                                            <h5 className='mt-2'>Submit&nbsp;&nbsp;
                                                <img width="40" src={imageName.getstart}></img>
                                            </h5>
                                        </div> */}

                                        <div className='onsite' style={{ cursor: 'pointer' }} onClick={onSubmit}>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <h5 className='mt-2'>Submit<img width='40' src={imageName.getstart}></img></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className="col-md-8 mb-2 d-flex justify-content-center">
                                        <img className='logo1' src={imageName.logo1}></img>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className="col-md-8 mb-2 d-flex justify-content-center">
                                        <h5 style={{ fontWeight: '200', textTransform: 'none', fontSize: '18px', letterSpacing: '0' }}>
                                            Family owned and operated since 1976. We are accredited appraisal experts that produce independent valuation reports that meet the highest professional standards. Our appraisal experts are individually accredited by ISA, AAA, or GIA.
                                        </h5>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                    <div className="col-md-8 mb-2 d-flex justify-content-center">
                                        <h4 style={{ letterSpacing: '20px' }}>
                                            <span className="q_social_icon_holder with_link circle_social">
                                                <a href="https://www.facebook.com/valueprosUS" target="_blank" rel="noopener">
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </span>
                                            <span className="q_social_icon_holder with_link circle_social">
                                                <a href="https://www.instagram.com/value_pros/" target="_blank" rel="noopener">
                                                    <i className="fab fa-instagram-square"></i>
                                                </a>
                                            </span>
                                            <span className="q_social_icon_holder with_link circle_social">
                                                <a href="https://www.linkedin.com/company/valuepros" target="_blank" rel="noopener">
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </span>
                                            <span className="q_social_icon_holder with_link circle_social">
                                                <a href="https://www.youtube.com/user/Nugentappraisal/" target="_blank" rel="noopener">
                                                    <i className="fab fa-youtube"></i>
                                                </a>
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default Help;