import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import Header from './Header';
import { getItemResponse, getUserData } from '../common/Common';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useParams } from 'react-router-dom';
import { finalPayment, getExaminationRes } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { MdEdit } from "react-icons/md";

export default function EditItem() {
    const navigate = useNavigate();
    let qty = "",
        price = "",
        total = "",
        examinationId = "",
        itemArr = [];
    if (localStorage.getItem("payInfo")) {
        qty = JSON.parse(localStorage.getItem("payInfo")).itemCount;
        price = JSON.parse(localStorage.getItem("payInfo")).price;
        total = JSON.parse(localStorage.getItem("payInfo")).total;
        examinationId = JSON.parse(localStorage.getItem("payInfo")).id;
    }

    if (localStorage.getItem("valueprosData")) {
        console.log("Valuepros Exam data", JSON.parse(localStorage.getItem("valueprosData")));
        itemArr = JSON.parse(localStorage.getItem("valueprosData")).items;
    }
    let userId = getUserData().data.user.id;
    let clientId = "ATvW1lrbt6uCwZ10sIzK7dijE6mZ2HMBKK1h1iFG4w3h3HpIf51osW7DD5x8Of1MmzJAUxl_b-usLr70"

    const [id, setid] = useState('')
    const [value, setValue] = useState([]);
    var description = ''
    var custom_id = ''
    // var examinationId = ''
    var arr = []



    useEffect(() => {

        var data1 = {
            "examinationId": examinationId,
            "userId": userId
        }
        // console.log(data1)
        getExaminationRes(data1).then(res => {
            console.log("response fetch :", res)
            if (res.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (res.message == "Success") {
                setValue(res.data)
                console.log("examinationData>>>>>", value)
            } else {
                // console.log("getExaminationRes---------------> Data fetch not successful");
            }
        })
    }, []);

    function editItem(item){
        localStorage.setItem("EditItemData", JSON.stringify(item));
        navigate("/item/edit")
    }

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='row d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='row d-flex justify-content-center mt-2'>
                            <div className="col-md-11 menu-card2">
                                <div className="row">
                                    <div className="login-wrapper d-flex justify-content-center align-items-center min-vh-100">
                                        <div className='verticalAlign'>
                                            <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "500", fontSize: '32px' }}>
                                                <label>Edit Item</label>
                                            </div>

                                            <div className='payOutline'>
                                                <div className='row'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Items</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                itemArr.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{item.itemName}</td>
                                                                        <td><MdEdit size={20} style={{ cursor: "pointer" }}  onClick={()=>editItem(item)} /></td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <div className='row'>
                                                    <div className='hr'></div>
                                                </div>
                                                <div className='row d-flex justify-content-end'>
                                                    <div className='col-md-12 d-flex justify-content-end item'>
                                                        <span style={{ fontSize: '24px', fontWeight: '600' }}>Total: &nbsp;&nbsp;{`$` + total + ".00"}</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 d-flex justify-content-center'>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* onClick={checkOut} */}
                                    <div className='checkOut' style={{ cursor: "pointer" }}>
                                        <h5 className='mt-2'>Checkout&nbsp;&nbsp;
                                            <img width="40" src={imageName.getstart}></img></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );

}