
import { getUserData } from '../common/Common'


// var baseURL = 'http://52.201.119.41:9756'
// var baseURL2 = 'http://demo.lykstage.com:9755'

// var baseURL = 'http://52.44.50.131:9756'
// var baseURL2 = 'http://52.44.50.131:9755'

var baseURL = 'http://api.valuepros.com:9756'
var baseURL2 = 'http://api.valuepros.com:9756'

export function signUp(data) {
   return fetch(baseURL + '/api/landlords/newregister', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function verifyEmail(data) {
   return fetch(baseURL + '/api/landlords/email-verify', {
      method: 'PUT', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function logIn(data) {
   return fetch(baseURL + '/api/signin', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function forgotPass(data) {
   return fetch(baseURL + '/api/forgot-password', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function resetPass(data) {
   return fetch(baseURL + '/api/reset-password', {
      method: 'PUT', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function ChangePass(data) {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/change-password', {
      method: 'PUT', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function fetchUserDetails() {
   var id = getUserData().data.user.id
   var token = getUserData().data.token

   return fetch(baseURL + '/api/users/user-full-details/' + id, {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function updUserDetails(data) {
   var id = getUserData().data.user.id
   var token = getUserData().data.token

   return fetch(baseURL + '/api/users/update-user', {
      method: 'PUT', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function getUserNotif(data) {
   var id = getUserData().data.user.id
   var token = getUserData().data.token

   return fetch(baseURL + '/api/notifications?pageNo='+data.pageNo+'&limit='+data.limit, {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function delUserNotif(id) {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/notifications/' + id, {
      method: 'DELETE', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function uploadFile(data) {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/files', {
      method: 'POST', // or 'PUT'
      headers: {
         "Authorization": "Bearer " + token
      },
      body: data,
   }).then(res => res.json())
}

export function getCatOpt() {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/inventories/category-options', {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function getReason() {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/examination/reasons', {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function getPricing() {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/pricing-plans/?pageNo=1&limit=100', {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function addExamination(data) {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/examination/add-examination', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function orderExamination(data) {
   var token = getUserData().data.token

   return fetch("https://www.sandbox.paypal.com/v2/checkout/orders", {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function getExaminationRes(data) {
   return fetch(baseURL2 + '/api/v1/examination/getUploadedItemByExamination', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function finalPayment(data) {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/transactions/paypal', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function userQuery(data) {

   return fetch(baseURL + '/api/usersQuery', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json"
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function getBill(data) {
   var token = getUserData().data.token

   if (data.search == "") {
      return fetch(baseURL + '/api/transactions/transactionHistory?pageNo=' + data.pageNo + '&limit=' + data.limit, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }else{
      return fetch(baseURL + '/api/transactions/transactionHistory?pageNo=' + data.pageNo + '&limit=' + data.limit + '&search=' + data.search, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }
   
}

export function getAppDash(data) {
   var token = getUserData().data.token
   if (data.search == "") {
      return fetch(baseURL + '/api/reports/examination-apprisal-report/?pageNo=' + data.pageNo + '&limit=' + data.limit, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   } else {
      return fetch(baseURL + '/api/reports/examination-apprisal-report/?pageNo=' + data.pageNo + '&limit=' + data.limit + '&search=' + data.search, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }
}

export function getAppDashNew(data) {
   var token = getUserData().data.token

   if (data.search == "") {
      return fetch(baseURL + '/api/examination/examiation-status-list?pageNo=' + data.pageNo + '&limit=' + data.limit, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }else{
      return fetch(baseURL + '/api/examination/examiation-status-list?pageNo=' + data.pageNo + '&limit=' + data.limit + '&search=' + data.search, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }
}

export function getValDashNew(data) {
   var token = getUserData().data.token

   if (data.search == "") {
      return fetch(baseURL + '/api/examination/examiation-valuation-status-list?pageNo=' + data.pageNo + '&limit=' + data.limit, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }else{
      return fetch(baseURL + '/api/examination/examiation-valuation-status-list?pageNo=' + data.pageNo + '&limit=' + data.limit + '&search=' + data.search, {
         method: 'GET', // or 'PUT'
         headers: {
            'Content-Type': 'application/json',
            "accept": "application/json",
            "Authorization": "Bearer " + token
         },
         body: JSON.stringify(),
      }).then(res => res.json())
   }
}

export function getHelpFAQ() {
   return fetch(baseURL + '/api/faq/list/?pageNo=1&limit=10000', {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
      },
      body: JSON.stringify(),
   }).then(res => res.json())
}

export function addHelp(data) {
   var token = getUserData().data.token

   return fetch(baseURL + '/api/supportWeb', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function promoCode(data) {
   var token = getUserData().data.token
   var examinationId = JSON.parse(localStorage.getItem("payInfo")).id

   return fetch(baseURL + '/api/examination/'+examinationId+'/promo', {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function delPromoCode(data) {
   var token = getUserData().data.token
   var examinationId = JSON.parse(localStorage.getItem("payInfo")).id

   return fetch(baseURL + '/api/examination/'+examinationId+'/promo/'+data.promoCode, {
      method: 'DELETE', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}

export function seenNotif(data) {
   var token = getUserData().data.token
   var userId = getUserData().data.user.id

   return fetch(baseURL + '/api/notifications/seen?userID='+userId, {
      method: 'POST', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
         "accept": "application/json",
         "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data),
   }).then(res => res.json())
}