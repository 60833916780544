import { PropTypes } from 'prop-types';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import { signUp } from '../RestAPI/Rest';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function SignupModal({
    isVisible,
    onRequestVisible,
    onLoggedIn
}) {
    const navigate = useNavigate();
    var [fname, setfname] = useState("");
    var [lname, setlname] = useState("");
    var [email, setemail] = useState("");
    var [mobile, setmobile] = useState("");
    var [pass, setpass] = useState("");
    var [cnfpass, setcnfpass] = useState("");
    var [check1, setCheck1] = useState(true);
    var [check2, setCheck2] = useState(true);
    const [fnamenotify, setfnamenotify] = useState('');
    const [lnamenotify, setlnamenotify] = useState('');
    const [emailnotify, setemailnotify] = useState('');
    const [mobilenotify, setmobilenotify] = useState('');
    const [passnotify, setpassnotify] = useState('');
    const [cnfpassnotify, setcnfpassnotify] = useState('');
    const [mismatchpassnotify, setmismatchpassnotify] = useState('');

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });

    const [cnfvalues, setcnfValues] = React.useState({
        password: "",
        showPassword: false,
    });

    // show pass eye icon
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowCnfPassword = () => {
        setcnfValues({ ...cnfvalues, showPassword: !cnfvalues.showPassword });
    };

    // for closing the modal
    const onRequestModal = () => {
        onRequestVisible(false);
        setInitialStateData();
    }

    // format phone number
    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + "-" + match[2] + "-" + match[3];
        } else {
            let newPhNum = "";
            for (let i = 0; i < cleaned.length; i++) {
                if (i === 3 || i === 6) {
                    newPhNum = newPhNum + "-";
                } else if (i >= 10) {
                    break;
                }
                newPhNum = newPhNum + cleaned.substr(i, 1);
            }
            return newPhNum;
        }
    }

    // for set the initial
    const setInitialStateData = () => {
        setfname('');
        setlname('');
        setpass('');
        setcnfpass('');
        setemail('');
        setmobile('');
        setCheck1(true);
        setCheck2(true);
        setfnamenotify('');
        setlnamenotify('');
        setemailnotify('');
        setmobilenotify('');
        setpassnotify('');
        setcnfpassnotify('');
        setmismatchpassnotify('');
    }

    // for Signup
    const submitSignUp = () => {
        // var regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{6,15}$/;
        var regexPass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])([a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]{8,})$/;
        if (fname == '') {
            setfnamenotify("Enter first name");
            setlnamenotify('');
            setemailnotify('');
            setmobilenotify('');
            setpassnotify('');
            setcnfpassnotify('');
            setmismatchpassnotify('');
            return false;
        } else {
            setfnamenotify('');
        }
        if (lname == '') {
            setlnamenotify("Enter last name");
            setfnamenotify('');
            setemailnotify('');
            setmobilenotify('');
            setpassnotify('');
            setcnfpassnotify('');
            setmismatchpassnotify('');
            return false;
        } else {
            setlnamenotify('');
        }
        if (email == '') {
            setemailnotify("Enter email");
            setfnamenotify('');
            setlnamenotify('');
            setmobilenotify('');
            setpassnotify('');
            setcnfpassnotify('');
            setmismatchpassnotify('');
            return false;
        } else {
            setemailnotify('');
        }
        if (mobile == '') {
            setmobilenotify("Enter mobile number");
            setfnamenotify('');
            setlnamenotify('');
            setemailnotify('');
            setpassnotify('');
            setcnfpassnotify('');
            setmismatchpassnotify('');
            return false;
        } else {
            setmobilenotify('');
        }
        if (pass != '') {
            if (!regexPass.test(pass)) {
                setpassnotify("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                setfnamenotify('');
                setlnamenotify('');
                setemailnotify('');
                setmobilenotify('');
                setcnfpassnotify('');
                setmismatchpassnotify('');
                return false;
            } else {
                setpassnotify('');
            }
        }
        if (cnfpass != '') {
            if (!regexPass.test(cnfpass)) {
                setcnfpassnotify("Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character");
                setfnamenotify('');
                setlnamenotify('');
                setemailnotify('');
                setmobilenotify('');
                setpassnotify('');
                setmismatchpassnotify('');
                return false;
            } else {
                setcnfpassnotify('');
            }
        }
        if (pass != cnfpass) {
            setmismatchpassnotify("Password & Confirm Password mismatch");
            setfnamenotify('');
            setlnamenotify('');
            setemailnotify('');
            setmobilenotify('');
            setpassnotify('');
            setcnfpassnotify('');
            return false;
        } else {
            setmismatchpassnotify('');
        }
        var info = {
            "firstName": fname,
            "lastName": lname,
            "password": pass,
            "email": email,
            "mobile": mobile.split('-').join(''),
            "signUpCheckBox": false,
            "policyCheck": check1,
            "optMail": check2
        };
        signUp(info).then(res => {
            if (res.success) {
                setInitialStateData();
                onLoggedIn(res.data.user);
                let path = `/verify-email`;
                navigate(path);
            } else {
                toast.error(res.message);
            }
        })
    }
    return (
        <Modal show={isVisible} onHide={() => onRequestModal()} centered>
            <Modal.Body>
                <div className="container">
                    <div className='verticalAlign'>
                        <h2 className='logotxt1'>
                            <img className='logo' src={imageName.logo} />
                        </h2>
                        <div className='mt-3 mb-3' style={{ textAlign: "center", fontWeight: "600" }}>
                            <label >Your single valuation source for all assets</label>
                            <br></br>
                            <label>Create your free account now</label>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mb-3'>
                                <label>*First Name:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={fname}
                                    onChange={(e) => setfname(e.target.value)}
                                />
                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{fnamenotify}</div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>*Last Name:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={lname}
                                    onChange={(e) => setlname(e.target.value)}
                                />
                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{lnamenotify}</div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>*Email:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{emailnotify}</div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>*Mobile Number<span style={{ fontSize: "12px" }}> (We may text you about an item, if necessary.)</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={formatPhoneNumber(mobile)}
                                    onChange={(e) => setmobile(e.target.value)}
                                />
                                <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{mobilenotify}</div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>*Password:</label>
                                <p style={{ fontSize: '12px', color: '#275F98' }}>Password must have 8 characters contains of at least one lowercase letter, one uppercase letter, one numeric digit, and one special character</p>
                                <div className='row'>
                                    <div className="col-md-12 passinp">
                                        <input id="password"
                                            className="form-control"
                                            type={values.showPassword ? "text" : "password"}
                                            placeholder=""
                                            name="password"
                                            value={pass}
                                            onChange={(e) => setpass(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{passnotify}</div>
                                    </div>
                                    <div className="eyecss">
                                        <span onClick={handleClickShowPassword} className={values.showPassword ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw field-icon toggle-password fa-eye-slash"}></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>*Confirm Password:</label>
                                <div className='row'>
                                    <div className="col-md-12 passinp">
                                        <input id="password1"
                                            className="form-control"
                                            type={cnfvalues.showPassword ? "text" : "password"}
                                            placeholder=""
                                            name="password"
                                            value={cnfpass}
                                            onChange={(e) => setcnfpass(e.target.value)}
                                        />
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{cnfpassnotify}</div>
                                    </div>
                                    <div className="eyecss">
                                        <span onClick={handleClickShowCnfPassword} className={cnfvalues.showPassword ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw field-icon toggle-password fa-eye-slash"}></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div style={{ color: '#E74C3C', fontSize: '15px', textAlign: 'left', fontWeight: '600' }}>{mismatchpassnotify}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <Form>
                                    <label>*Required fields</label>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            // value={true}
                                            onChange={(e) => setCheck1(!check1)}
                                            checked={check1}
                                            // onChange ={handleCheckboxClick}
                                            label="By signing up for this app, you agree to our Privacy Policy, Terms and Conditions and Acceptable Use Policy."
                                        />
                                    </Form.Group>
                                    <br />
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            value={true}
                                            onChange={(e) => setCheck2(!check2)}
                                            checked={check2}
                                            label="Check here to receive emails with special offers, application updates, and relevant content. You
                                                may unsubscribe at any time."
                                        />
                                    </Form.Group>
                                </Form>
                            </div>

                            <div className='col-md-12 mb-3'>
                                {
                                    (fname && lname && email && mobile && pass && cnfpass && check1) ?
                                        <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px', cursor: 'pointer' }}>
                                            <Button className='btn btnCreateAcc' type='submit' onClick={() => submitSignUp()}>Create Account</Button>
                                        </div> :
                                        <div className='text-center' style={{ marginTop: '20px', paddingBottom: '30px' }}>
                                            <Button className='btn btnCreateAcc1' type='submit' disabled>Create Account</Button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}


SignupModal.defaultProps = {
    isVisible: false,
    onRequestVisible: () => { },
    onLoggedIn: () => { }
};

SignupModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onRequestVisible: PropTypes.func,
    onLoggedIn: PropTypes.func
};


export default SignupModal;