import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { imageName } from '../../assets/enums';
import Form from 'react-bootstrap/Form';
import Header from './Header';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import { delUserNotif, getUserNotif, seenNotif } from '../RestAPI/Rest';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function Notifications() {
    const navigate = useNavigate();
    var [userNotif, setuserNotif] = useState([]);

    function notificationTime(value) {
        let ntTime = moment(value).fromNow();
        return ntTime;
    }

    const delNotif = (id, i) => {
        console.log(userNotif)
        var src = [...userNotif]

        delUserNotif(id).then(res => {
            if (res.success) {
                toast.success("Notification deleted successfully");
                src.splice(i, 1)
                setuserNotif(src)
            } else {
                toast.error("Notification didn't deleted successfully");
                if (res.code == 401) {
                    localStorage.clear()
                    let path = `/`
                    navigate(path)
                    setTimeout(() => {
                        toast.error("Sorry, Session Expired");
                    }, 1000);
                }
            }
            // let path = `/notifications`
            // navigate(path)
        })
    }

    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            let path = `/`
            navigate(path)
        }

        var data = {
            'pageNo': 1,
            'limit': 10
        }

        getUserNotif(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if (result.success) {
                // console.log(result)
                setuserNotif(result.data.data)
                seenNotif()
            }
        })
    }, [])

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-12 menu-card2">

                                {
                                    userNotif.map((item, i) => (
                                        <div className='mt-3 mb-3 d-flex justify-content-center' >
                                            <div className='col-md-10 notifBorder'>
                                                <div className='row'>
                                                    <div className='col-md-11 d-flex justify-content-start'>
                                                        {item.isSeen ?
                                                            null :
                                                            <span className='notiflist' />
                                                        }
                                                        <span>{item.subject}&nbsp;&nbsp;<span style={{color: '#1876f2', fontSize: '15px'}}>{notificationTime(item.createdAt)}</span></span>
                                                    </div>
                                                    <div className='col-md-1'>
                                                        <img src={imageName.deleteicon} width="20" style={{ cursor: 'pointer' }} onClick={() => delNotif(item.id, i)}></img>
                                                    </div>
                                                </div>

                                                {/* <span>{item.subject}</span> */}
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    );
}