import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import Form from 'react-bootstrap/Form';
import { verifyEmail } from '../RestAPI/Rest';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function ValidateAcc() {
    const navigate = useNavigate();
    let { id } = useParams();
    var isLogin = false


    const goLogin = () => {
        let path = `/`
        navigate(path)
        // window.open(path);
    }

    const validate = () => {
        var data = {
            "token": id
        }
        verifyEmail(data).then(res => {
            if(res.code == 401){
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            if(res.success){
                // toast.success("Email verified successfully");
                isLogin = true
            }else{
                toast.error("Email verified not successfully");
            }
        })
    }

    useEffect(() => {
        console.log(id, isLogin)
        validate()
    }, [])

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        {/* <div className='row d-flex justify-content-center'>
                            <Header />
                        </div> */}

                        <div className='d-flex justify-content-center mt-2'>
                            <div className="col-md-11 menu-card2">
                                <div className='row'>
                                    {/* <div className='col-md-12'>
                                        <h5 style={{ textAlign: 'left', marginTop: '20px', fontSize: '18px' }}>Verify Email with Token</h5>
                                        <input className='form-control' placeholder='Enter token' type="text" onChange={(e) => { setverifyEmail(e.target.value) }} />
                                    </div>

                                    <div className="col-md-6">
                                        <div className='verify' style={{ cursor: "pointer" }} onClick={verify}>
                                            <h5 className='mt-2'>Verify&nbsp;&nbsp;
                                                <img width="40" src={imageName.getstart}></img></h5>
                                        </div>
                                    </div> */}
                                    <div className='col-md-12'>
                                        <img className='logo1' src={imageName.logo} />
                                    </div>

                                    <div className='col-md-12 mt-2 mb-3'>
                                        <h3 style={{fontSize: '15px'}}>Your email verified successfully...</h3>
                                    </div>
                                        
                                    <div className='col-md-12 mt-2 mb-3'>
                                        <a onClick={goLogin} style={{cursor: 'pointer', color: '#0671b2', textDecoration: 'underline'}}><b>LOGIN</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )
}

export default ValidateAcc;