import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Dashboard from './app/components/Dashboard';
import Login from './app/components/Login';
import Adminlogin from './app/components/Adminlogin';
import Appraisal from './app/components/Appraisal';
import Item from './app/components/Item';
import ResetPass from './app/components/ResetPass';
import Payment from './app/components/Payment';
import AccountSetting from './app/components/AccountSetting';
import Notifications from './app/components/Notifications';
import ValuationDash from './app/components/ValuationDash';
import AppraisalDash from './app/components/AppraisalDash';
import SignUp from './app/components/Signup';
import VerifyEmail from './app/components/VerifyEmail';
import ValidateAcc from './app/components/ValidateAcc';
import Billing from './app/components/Billing';
import LearnMore from './app/components/LearnMore';
import Help from './app/components/Help';
import Paypal from './app/components/Paypal';
import ProceedPay from './app/components/ProceedPay';
import './App.css'
import EditItem from './app/components/EditItem';
import ItemList from './app/components/ItemList';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={< Dashboard />}></Route>
            <Route path='/valuation-dashboard' element={< ValuationDash />}></Route>
            <Route path='/appraisal-dashboard' element={< AppraisalDash />}></Route>
            <Route path='/billing' element={< Billing />}></Route>
            <Route path='/appraisal' element={< Appraisal />}></Route>
            <Route path='/item' element={< Item />}></Route>
            <Route path='/account/reset-password/:id' element={< ResetPass />}></Route>
            <Route path='/verify-email' element={< VerifyEmail />}></Route>
            <Route path='/validated/:id' element={< ValidateAcc />}></Route>
            <Route path='/payment' element={< Payment />}></Route>
            <Route path='/proceed-pay' element={< ProceedPay />}></Route>
            <Route path='/paypal' element={< Paypal />}></Route>
            <Route path='/account-setting' element={< AccountSetting />}></Route>
            <Route path='/notifications' element={< Notifications />}></Route>
            <Route path='/admin-login' element={< Adminlogin />}></Route>
            <Route path='/learn-more' element={< LearnMore />}></Route>
            <Route path='/help' element={< Help />}></Route>
            <Route exact path="/editItem" element={<EditItem />} />
            <Route exact path="/item/edit/:id" element={<Item />} />
            <Route exact path="/item/add" element={<Item />} />
            <Route path="/item-list" element={<ItemList />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;