import React, { useEffect, useRef, useState } from 'react';
import { Pagination } from '@mui/material';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { imageName } from '../../assets/enums';
import Header from './Header';
import { getAppDash, getAppDashNew } from '../RestAPI/Rest';
import { LIMIT_ARRAY } from '../constant/ConstValue';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function AppraisalDash() {
    const navigate = useNavigate();
    var searchImg = imageName.search
    var [appDash, setappDash] = useState([]);
    var [res, setRes] = useState({});
    var [count, setCount] = useState(0);
    var [totPage, settotPage] = useState(0);
    var [listItem, setlistItem] = useState([]);
    var [pageNo, setpageNo] = useState(1)
    var [start, setStart] = useState(1)
    var [end, setEnd] = useState(10)
    var [limit, setLimit] = useState(10)
    const [limitArr, setLimitArr] = useState([]);
    // const [limit, setLimit] = useState("10");
    var [search, setsearch] = useState('')
    var [rownum, setrownum] = useState(0)

    // var limit = 10

    const formatdate = (val) => {
        let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"]
        let nd = new Date(val);
        console.log(val)
        let year = nd.getFullYear();
        let month = ("0" + (nd.getMonth()+1)).slice(-2);
        let day = ("0" + nd.getDate()).slice(-2)
        let str = month + "/" + day + "/" + year;
        return str;
    }

    const next = () => {
        var page_no = pageNo
        page_no += 1
        setpageNo(page_no)

        var st = parseInt(start)
        st += parseInt(limit)
        setStart(st)

        var nd = parseInt(end)
        nd += parseInt(limit)
        setEnd(nd)

        var data = {
            'pageNo': page_no,
            'limit': limit,
            'search': search
        }
        getAppDashNew(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setappDash(result.data.data)
            settotPage(result.data.pageNumber)
        })
    }

    const prev = () => {
        var page_no = pageNo
        page_no -= 1
        setpageNo(page_no)

        var st = start
        st -= limit
        setStart(st)

        var nd = end
        nd -= limit
        setEnd(nd)

        var data = {
            'pageNo': page_no,
            'limit': limit,
            'search': search
        }
        getAppDashNew(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setappDash(result.data.data)
            settotPage(result.data.pageNumber)
        })
    }

    const clickPage = (item) => {
        setpageNo(item)

        var st = (limit * item) - (limit - 1)
        var nd = (limit * item)
        setStart(st)
        setEnd(nd)

        var data = {
            'pageNo': item,
            'limit': limit,
            'search': search
        }
        var totalitem = []
        getAppDashNew(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                setTimeout(() => {
                    toast.error("Sorry, Session Expired");
                }, 1000);
            }
            setRes(result.data)
            setappDash(result.data.data)
            settotPage(result.data.pageNumber)
            for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                totalitem.push(i + 1)

            }
            setlistItem(totalitem)
        })
    }

    const searchReport = (event) => {
        if (search.length >= '3') {
            var data = {
                'pageNo': pageNo,
                'limit': limit,
                'search': search
            }
            var totalitem = []
            getAppDashNew(data).then(result => {
                if (result.code == 401) {
                    localStorage.clear()
                    let path = `/`
                    navigate(path)
                    setTimeout(() => {
                        toast.error("Sorry, Session Expired");
                    }, 1000);
                }
                setRes(result.data)
                setappDash(result.data.data)
                setCount(result.data.total)
                settotPage(result.data.pageNumber)
                for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                    totalitem.push(i + 1)

                }
                setlistItem(totalitem)
            })
        }

        if (search.length == '0') {
            var data = {
                'pageNo': pageNo,
                'limit': limit,
                'search': search
            }
            var totalitem = []
            getAppDashNew(data).then(result => {
                setRes(result.data)
                setappDash(result.data.data)
                setCount(result.data.total)
                settotPage(result.data.pageNumber)
                for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                    totalitem.push(i + 1)

                }
                setlistItem(totalitem)
            })
        }
    }

    // useEffect(() => {
    //     getAppFun();
    //     setLimitArr(LIMIT_ARRAY)
    // }, [limit])

    function showReport(report){
        console.log(report)
        window.open(report, "_blank");
    }

    function pageLimitChange(e) {
        setLimit(e.target.value);

        var st = (e.target.value - (e.target.value - 1))
        var nd = (e.target.value)
        setStart(st)
        setEnd(nd)
        setpageNo(1)
    }

    function getAppFun() {
        var data = {
            'pageNo': pageNo,
            'limit': limit,
            'search': search
        }
        var totalitem = []
        getAppDashNew(data).then(result => {
            if (result.code == 401) {
                localStorage.clear()
                let path = `/`
                navigate(path)
                toast.error("Sorry, Session Expired");
            }
            setRes(result.data)
            setappDash(result.data.data)
            // console.log(result.data.data)
            setCount(result.data.total)
            settotPage(result.data.pageNumber)
            for (let i = 0; i < Math.ceil(result.data.total / limit); i++) {
                totalitem.push(i + 1)
            }
            setlistItem(totalitem)
        })
    }

    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("userInfo"))) {
            let path = `/`
            navigate(path)
        } else {
            getAppFun()
            setLimitArr(LIMIT_ARRAY)
        }
    }, [limit])

    return (
        <>
            <div className='body'>
                <div className='container'>
                    <div className="card mt-2">
                        <div className='d-flex justify-content-center'>
                            <Header />
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="col-md-12 mt-3 mb-3 d-flex justify-content-start">
                                <span><img src={imageName.house} width='15'></img>&nbsp;&nbsp;Home / <b>Appraisal Dashboard</b></span>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <div className="col-md-12 menu-card2">
                                <div className='row d-flex justify-content-center'>
                                    <div className="col-md-11 mt-2 d-flex justify-content-start">
                                        <div className='row'>
                                            <div className='col-md-12 d-flex justify-content-start'>
                                                <img className='valdollar' src={imageName.appraisal}></img>&nbsp;&nbsp;<h4 className='text-left valcer'>Appraisal Dashboard</h4>
                                            </div>
                                            {/* <div className="col-md-2 mt-2 d-flex justify-content-start">
                                                <img className='dollar' src={imageName.appraisal} />
                                            </div>
                                            <div className="col-md-10 mt-3 d-flex justify-content-start">
                                                <span style={{ fontSize: '24px', fontWeight: '500' }}>
                                                    Appraisal Dashboard
                                                </span>
                                            </div> */}
                                        </div>
                                        {/* <span style={{ fontSize: '24px', fontWeight: '500' }}><img className='dollar' src={imageName.appraisal} />
                                            &nbsp;&nbsp;Appraisal Dashboard</span> */}
                                    </div>
                                </div>

                                <div className='row mt-4 d-flex justify-content-center'>
                                    <div className="col-md-11 d-flex justify-content-start">
                                        <h3 style={{ fontSize: '18px', fontWeight: '500' }}>Appraisal list items</h3>
                                    </div>
                                </div>

                                <div className='row d-flex justify-content-center'>
                                    <div className="col-md-11 mt-1 d-flex justify-content-start">
                                        <label>Find an archive of your appraisal reports and other important information.</label>
                                    </div>
                                </div>

                                <div className='row d-flex justify-content-center'>
                                    <div className="col-md-11 mt-3 mb-3">
                                        <div className='row d-flex justify-content-start'>
                                            <div className="col-md-6 searchCss d-flex justify-content-start">
                                                {/* <img className='srchimg' src={searchImg}></img> */}
                                                <input type="text" id="" className="form-control" placeholder="Search items ..." aria-label="Search" style={{ borderRadius: '25px', backgroundColor: '#f6f6f6' }} value={search} onChange={(e) => { setsearch(e.target.value) }} onKeyUp={searchReport} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row d-flex justify-content-center'>
                                    <div className="col-md-11 table-responsive" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="col-md-12 px-0">
                                            <div className="tblHeadAlt tblGreyHead">
                                                <div className='firstcol' style={{ width: '20%' }}>
                                                    <h5 style={{textAlign: 'left'}}>DATE</h5>
                                                </div>
                                                <div className='firstcol' style={{ width: '25%' }}>
                                                    <h5 style={{textAlign: 'left'}}>ORDER ID</h5>
                                                </div>
                                                <div className='firstcol' style={{ width: '25%' }}>
                                                    <h5 style={{textAlign: 'center'}}>APPRAISAL AMOUNT</h5>
                                                </div>
                                                <div className='firstcol' style={{ width: '30%' }}>
                                                    <h5 style={{textAlign: 'center'}}>STATUS</h5>
                                                </div>
                                            </div>

                                            {/* <div className="col-md-11 table-responsive"> */}
                                            <div className="">
                                                {/* {
                                                    console.log(appDash)
                                                } */}
                                                {
                                                    appDash.length > 0 ?
                                                        <div className=''>
                                                            {
                                                            
                                                            appDash.map((item, index) =>
                                                                // index >= 0?
                                                                    <div className='border-bt py-2 td-row' key={index}>
                                                                        {
                                                                            item.examination.appraisalCompleteDate ?
                                                                                <div className='firstcol width20 text-left pl-3'> {formatdate(item.examination.appraisalCompleteDate)}</div> :
                                                                                <div className='firstcol width20 text-left pl-3'>
                                                                                    {formatdate(item.examination.examinationRequestDate)}
                                                                                </div>
                                                                        }

                                                                        {
                                                                            <div className='firstcol width25 text-left'>{item.examination.examinationNo}</div>
                                                                        }

                                                                        {
                                                                            Array.isArray(item.report) ?
                                                                                <div className='firstcol width25'>$&nbsp;{item.report[0].appraisalAmount + ".00"}</div> : <div className='firstcol width25'>{"--"}</div>
                                                                        }

                                                                        <div className='firstcol width30'>
                                                                            {
                                                                                Array.isArray(item.report) ?
                                                                                    item.report[0].status == "generated" ?
                                                                                        <span className='gen'>Generated</span> : item.report[0].status == "published" ? <span className='pub'>Published</span> :
                                                                                            <span className='pen'>Pending</span>
                                                                                    : <span className='pen'>Pending</span>
                                                                            }

                                                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                                                            {

                                                                                Array.isArray(item.report) ?
                                                                                    item.report[0].status == "generated" ?
                                                                                        <a onClick={()=>showReport(item.report[0].src)} style={{cursor:"pointer"}}><i className="fas fa-file-pdf" style={{ fontSize: '20px', color: '#e4283f' }}></i></a> : <a onClick={()=>showReport(item.report[0].src)}  style={{cursor:"pointer"}}><i className="fas fa-file-pdf" style={{ fontSize: '20px', color: '#e4283f' }}></i></a>
                                                                                    : <i className="fas fa-file-pdf" style={{ fontSize: '20px', color: '#c1c1c1' }}></i>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    // :null
                                                                )
                                                            }
                                                        </div> : <div className=''>
                                                            <h5 style={{ textAlign: 'center', color: "#848992" }}>No Data Available</h5>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row d-flex justify-content-center'>
                                    <div className="col-md-11 mb-3">
                                        <div className='row tblbottom'>
                                            <div className="col-md-4 d-flex justify-content-start">
                                                <div className="dataTables_length bs-select" id="dtBasicExample_length">
                                                    <label>
                                                        <select name="dtBasicExample_length" aria-controls="dtBasicExample" className="custom-select custom-select-sm form-control form-control-sm" value={limit} onChange={(e) => pageLimitChange(e)}>
                                                            {limitArr.map((item, i) =>
                                                                <option value={item.value} key={i}>{item.label}</option>
                                                            )}
                                                            {/* <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option> */}
                                                        </select>
                                                    </label>
                                                    &nbsp;&nbsp;

                                                    <span style={{ color: '#848992' }}>

                                                        Showing {start} - {end} of {res.pageNumber}

                                                    </span>


                                                </div>
                                            </div>
                                            <div className="col-md-8 d-flex justify-content-end">
                                                <div className="dataTables_paginate paging_simple_numbers" id="dtBasicExample_paginate">
                                                    <ul className="pagination">
                                                        {
                                                            pageNo == 1 ?
                                                                <li className="paginate_button page-item previous disabled" id="dtBasicExample_previous">
                                                                    {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="0" tabindex="0" class="page-link">Previous</a> */}
                                                                    <i className="fas fa-chevron-left designarr" style={{ marginRight: "20px", marginTop: "0px" }}></i>
                                                                </li> : <li className="paginate_button page-item previous disabled" id="dtBasicExample_previous" onClick={prev}>
                                                                    {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="0" tabindex="0" class="page-link">Previous</a> */}
                                                                    <i className="fas fa-chevron-left designarr" style={{ marginRight: "20px", marginTop: "0px" }}></i>
                                                                </li>
                                                        }

                                                        {
                                                            listItem.map((item, index) =>

                                                                <li className={"paginate_button page-item " + (pageNo == item ? "active" : "")} key={index} onClick={(e) => clickPage(item)}>
                                                                    <a aria-controls="dtBasicExample" data-dt-idx="2" tabIndex="0" className="page-link">{item}</a>
                                                                </li>

                                                            )
                                                        }
                                                        {
                                                            pageNo == listItem.length ?
                                                                <li className="paginate_button page-item next" id="dtBasicExample_next">
                                                                    {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="7" tabindex="0" class="page-link">Next</a> */}
                                                                    <i className="fas fa-chevron-right designarr" style={{ marginLeft: "20px", marginTop: "0px" }}></i>
                                                                </li> : <li className="paginate_button page-item next" id="dtBasicExample_next" onClick={next}>
                                                                    {/* <a href="#" aria-controls="dtBasicExample" data-dt-idx="7" tabindex="0" class="page-link">Next</a> */}
                                                                    <i className="fas fa-chevron-right designarr" style={{ marginLeft: "20px", marginTop: "0px" }}></i>
                                                                </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default AppraisalDash;